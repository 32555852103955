import { useEffect, useState } from 'react'

type PermissionState = 'waiting' | 'granted' | 'denied'

export const useMediaPermissions = ({
  onDenied,
}: {
  onDenied?: () => void
}) => {
  const [permissionState, setPermissionState] =
    useState<PermissionState>('waiting')

  useEffect(() => {
    // query for both audio and video devices at the same time
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then((mediaStream) => {
        const tracks = mediaStream.getTracks()
        tracks.forEach((track) => {
          track.stop()
        })
        setPermissionState('granted')
      })
      .catch(() => {
        setPermissionState('denied')
        onDenied?.()
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [permissionState, setPermissionState] as const
}
