import { PublicRoute, routes } from 'config/routes'
import { useAuthContext } from 'hooks/auth'
import { StoreProvider, useRootStore } from 'hooks/rootStore'
import { startRouter } from 'mobx-router'
import { LoginPage } from 'pages/login/LoginPage'
import { useEffect, useState } from 'react'
import { RootStore } from 'store/root'
import { LazyMotion, domAnimation } from 'framer-motion'
import { MobxRouter } from './MobxRouter'
import { Theme } from './Theme'
import { I18nWrapper } from 'i18n/core'
import { UserContextProvider } from 'hooks/profileContext'
import { SettingsContextProvider } from 'hooks/settings'
import { observer } from 'mobx-react-lite'
import { NotFoundPage } from 'pages/application/NotFoundPage'
import { StorageUrlContextProvider } from 'components/contexts/StorageUrlContextProvider'
import { DialogMount } from 'components/dialogs/DialogMount'
import { DialogContextProvider } from 'components/dialogs/DialogContextProvider'
import { ObjectUrlContextProvider } from 'components/contexts/ObjectUrlContextProvider'
import { AuthedRedirectHandler } from './AuthedRedirectHandler'
import { NewVersionBanner } from './NewVersionBanner'

const UnauthedMount = observer(function UnauthedMount() {
  const rootStore = useRootStore()

  const route = rootStore.router.currentRoute

  if (route instanceof PublicRoute) {
    const component = route.component
    return component
  } else if (route) {
    return <LoginPage />
  } else {
    return <NotFoundPage />
  }
})

function AuthedMount() {
  return (
    <UserContextProvider>
      <SettingsContextProvider>
        <DialogContextProvider>
          <MobxRouter />
          <DialogMount />
          <AuthedRedirectHandler />
        </DialogContextProvider>
      </SettingsContextProvider>
    </UserContextProvider>
  )
}

export function Entrypoint() {
  const [rootStore] = useState(() => new RootStore())
  useEffect(() => {
    startRouter(routes, rootStore)
  }, [rootStore])

  const { authenticated, initialized } = useAuthContext()

  useEffect(() => {
    // if we are on logout page and not authenticated, navigate to home, since we are logged out
    setTimeout(() => {
      if (rootStore.router.currentRoute?.title === 'logout' && !authenticated) {
        rootStore.navigateTo('home')
      }
    }, 100)
  }, [authenticated, rootStore])

  useEffect(() => {
    // convert hash based routes to history based routes
    const { hash, pathname } = window.location
    if (hash) {
      window.location.replace(pathname + hash.replace('#', ''))
    }
  })

  if (!initialized) return null

  return (
    <>
      <StoreProvider value={rootStore}>
        <Theme>
          <I18nWrapper>
            <ObjectUrlContextProvider>
              <StorageUrlContextProvider>
                <LazyMotion features={domAnimation}>
                  <div className="h-dvh w-dvw">
                    <div className="relative box-border flex h-full w-full flex-col items-center justify-center md:p-8 md:pb-8 md:pt-[32px]">
                      <NewVersionBanner />
                      {!authenticated && <UnauthedMount />}
                      {authenticated && <AuthedMount />}
                    </div>
                  </div>
                </LazyMotion>
              </StorageUrlContextProvider>
            </ObjectUrlContextProvider>
          </I18nWrapper>
        </Theme>
      </StoreProvider>
    </>
  )
}
