import { ValidLibraryObjectActionState } from '@breakoutlearning/firebase-repository/stores/ValidLibraryObject'
import { useStudentAssignmentCubit } from 'hooks/cubits/studentAssignment'
import { useDialogs } from 'hooks/dialogs'
import { useRootStore } from 'hooks/rootStore'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect } from 'react'
import { RoomStateForm } from './dialogs/RoomStateFormDialog'
import { RoomsDialog } from './dialogs/RoomsDialog'
import { QuizModal } from './QuizModal'
import { StartQuizModal } from './StudentAssignmentStep2'
import { LibraryObjectState } from '@breakoutlearning/firebase-repository/types'
import { useWalkthroughDialog } from './dialogs/WalkthroughDialog.hooks'
import { StartSessionDialog } from './dialogs/StartSessionDialog'
import { MeetingResultsDialog } from './dialogs/MeetingResultsDialog'
import { SummaryDialog } from './dialogs/SummaryDialog'

/**
 * To be used on the StudentAssignmentPage
 * this component will only perform any logic on the initial render
 * and will not render anything
 *
 * This component is responsible for handling various actions defined
 * in LibraryObject as as ValidLibraryObjectActionState.
 *
 * We see if there is an action defined in the 'action' query
 * parameter, and if it is a valid action (can be parsed to the enum),
 * and if the assignment/room is an appropriate state we perform that action on behalf of the user,
 *
 * i.e joinRoom => popGroup dialog, startSession -> popStartSession dialog
 *
 */
export const StudentAssignmentLibraryActionParser = observer(
  function StudentAssignmentLibraryActionParser() {
    const { showDialog, popDialog } = useDialogs()
    const store = useRootStore()
    const cubit = useStudentAssignmentCubit()
    const { showWalkthroughDialogIfNecessary } = useWalkthroughDialog({
      roomId: cubit.roomStateId,
    })

    // we return null from this function so the TS compiler
    // forces us to explicity acknowledge all possible actions (exhaustive switch)
    const runAction = useCallback(
      (action: ValidLibraryObjectActionState): null => {
        switch (action) {
          case ValidLibraryObjectActionState.joinGroup: {
            if (
              cubit.roomState.isEmpty &&
              !cubit.libraryObject.requiresPayment
            ) {
              showDialog(() => <RoomsDialog cubit={cubit} />)
            }
            return null
          }
          case ValidLibraryObjectActionState.scheduleSession: {
            if (cubit.roomState.isNotEmpty) {
              showDialog(() => (
                <RoomStateForm
                  onSave={() => {
                    popDialog()
                  }}
                  cubit={cubit}
                />
              ))
            }
            return null
          }
          case ValidLibraryObjectActionState.completeQuiz: {
            if (!cubit.allPreWorkQuestionsAnswered) {
              showDialog(({ remove: removeStartQuizModal }) => {
                return (
                  <StartQuizModal
                    onClick={() => {
                      showDialog(({ remove: removeQuizModal }) => {
                        return (
                          <QuizModal
                            cubit={cubit}
                            closeModal={() => {
                              removeQuizModal()
                            }}
                          />
                        )
                      })
                      removeStartQuizModal()
                    }}
                  />
                )
              })
            }
            // pre-meeting quiz dialog
            return null
          }
          case ValidLibraryObjectActionState.startSession: {
            const {
              libraryObject: { libraryObjectState },
            } = cubit

            const canStart =
              (libraryObjectState === LibraryObjectState.readyToStart ||
                (libraryObjectState === LibraryObjectState.live &&
                  !cubit.isRoomActive)) &&
              cubit.allPreWorkQuestionsAnswered &&
              cubit.roomState.isScheduled &&
              cubit.roomState.isNotEmpty

            if (canStart) {
              showDialog(({ remove }) => (
                <StartSessionDialog
                  onConfirmStartSession={async () => {
                    await cubit.startMeeting()
                    showWalkthroughDialogIfNecessary(() =>
                      cubit.setJoiningSession()
                    )
                    remove()
                  }}
                />
              ))
            }

            return null
          }
          case ValidLibraryObjectActionState.viewResults: {
            const { isCompleted } = cubit.roomState
            const hasRoomStartedAt =
              cubit.roomState.data.roomStartedAt !== undefined
            const newMeetingResultsEnabled =
              cubit.repository.featureFlags.data.sessionResultsUseNew
            const canShowNewResults =
              isCompleted &&
              hasRoomStartedAt &&
              newMeetingResultsEnabled &&
              Boolean(cubit.roomStateId)
            if (cubit.roomStateSummary.hasData || canShowNewResults) {
              showDialog(() => {
                if (canShowNewResults)
                  return <MeetingResultsDialog roomId={cubit.roomStateId!} />
                return (
                  <SummaryDialog
                    summary={cubit.roomStateSummary.data}
                    roomStateName={cubit.roomState.roomStateName}
                    roomStateActiveSlideChangedAt={
                      cubit.roomState.data.activeSlideChangedAt || null
                    }
                  />
                )
              })
            }
            return null
          }

          case ValidLibraryObjectActionState.enroll: // pops from reaction
          case ValidLibraryObjectActionState.joinSession: // pops from reaction
          case ValidLibraryObjectActionState.availableOn: // no op
          case ValidLibraryObjectActionState.sessionScheduled: // no op
          case ValidLibraryObjectActionState.pending: // no op
          case ValidLibraryObjectActionState.experienceExpired: // no op
            return null
        }
      },
      [cubit, popDialog, showDialog, showWalkthroughDialogIfNecessary]
    )

    useEffect(() => {
      const isReady =
        cubit.isReady &&
        cubit.repository.featureFlags.isLoaded &&
        (!cubit.roomStateId || (cubit.roomState.isLoaded && cubit.hasQuizData))
      if (!isReady) return
      const actionStr = (store.router.queryParams ?? {})['action']

      // once we've attempted to grab remove the param from the url
      runInAction(() => {
        if (store.router.queryParams && 'action' in store.router.queryParams) {
          // Ok, we need to do some manual work, because we want to do a replaceState
          const queryParams = new URLSearchParams(window.location.search)
          const stringifiedQueryParams = queryParams.toString()
          const newRelativePathQuery =
            window.location.pathname +
            (stringifiedQueryParams !== '' ? `?${stringifiedQueryParams}` : '')
          window.history.replaceState(null, '', newRelativePathQuery)
          store.updateQueryParams({ action: undefined })
        }
      })

      if (typeof actionStr !== 'string' || !actionStr) return
      const action = ValidLibraryObjectActionState[
        actionStr as keyof typeof ValidLibraryObjectActionState
      ] as ValidLibraryObjectActionState | undefined
      if (!action) return
      runAction(action)

      // action is defined at this point, attempt to perform the action
    }, [
      store,
      runAction,
      cubit.roomStateId,
      cubit.isReady,
      store.router.queryParams,
      cubit.repository.featureFlags.isLoaded,
      cubit.roomState.isLoaded,
      cubit.hasQuizData,
    ])

    return null
  }
)
