import classNames from 'classnames'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { ChevronRight } from 'components/icons/ChevronRight'
import { Spinner } from 'components/Spinner'
import { useMeetingResultsCubit } from 'hooks/cubits/meetingsResults'
import { useDialogs } from 'hooks/dialogs'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import type {
  RubricType,
  SlideRubric,
} from '@breakoutlearning/firebase-repository/models/SlideRubric'
import type { RoomStateRubricResult } from '@breakoutlearning/firebase-repository/models/RoomStateRubricResult'
import { SparklesIcon } from 'components/icons/Sparkles'
import { ThreeLineHorizontalIcon } from 'components/icons/ThreeLineHorizontal'
import { RubricResultMoreDetailsDialog } from './RubricResultMoreDetailsDialog'
import { getRubricBgColorByScore, getRubricScoreLabel } from 'util/rubric'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import { useWaitFor } from './use-wait-for'

export const IndividualEvaluation = observer(function IndividualEvaluation() {
  const { t } = useTranslation()
  const waitedFor3Min = useWaitFor(3)
  const cubit = useMeetingResultsCubit()

  if (!cubit.rubrics.isLoaded || !cubit.rubricResultsForCurrentUser.isLoaded) {
    return null
  }

  const showLoadingResults = cubit.aiStatus === 'pending' && !waitedFor3Min
  const showNoResults =
    cubit.aiStatus === 'failure' ||
    (cubit.aiStatus === 'pending' && waitedFor3Min)
  const aiResultsSuccess =
    cubit.aiStatus === 'success' && !cubit.rubricResultsForCurrentUser.isEmpty

  return (
    <>
      <div className="text-title-large">
        {t('meeting.individual_evaluation')}
      </div>
      {aiResultsSuccess && (
        <div className="text-body-medium">
          {t('meeting.individual_evaluation_description')}
        </div>
      )}
      <div className="mt-4 space-y-4">
        {aiResultsSuccess &&
          cubit.rubricResultsForCurrentUser.models.map((rubricResult) => {
            const rubric = cubit.rubrics.models.find(
              (r) => r.id === rubricResult.id
            )
            if (!rubric) {
              return null
            }
            return (
              <RubricResult
                key={rubricResult.id}
                rubric={rubric}
                rubricResult={rubricResult}
              />
            )
          })}
        {showNoResults && (
          <div className="text-body-medium text-on-surface">
            {t('meeting.individual_eval_no_results')}
          </div>
        )}
        {showLoadingResults && (
          <div>
            <div>
              <Spinner size={1.75} />
            </div>
            <span className="text-body-medium">
              {t('meeting.individual_eval_loading')}
            </span>
          </div>
        )}
      </div>
    </>
  )
})

const RubricResult = observer(function RubricResult({
  rubric,
  rubricResult,
}: {
  rubric: SlideRubric
  rubricResult: RoomStateRubricResult
}) {
  const { showDialog } = useDialogs()
  const { t } = useTranslation()
  const cubit = useMeetingResultsCubit()
  const [rubricDataForInlineDialog, setRubricDataForInlineDialog] = useState<{
    rubricResults: RoomStateRubricResult
    rubric: SlideRubric
  }>()

  return (
    <>
      <div
        key={rubricResult.id}
        data-testid={`individual-rubric-result-${rubricResult.id}`}
      >
        <div className="items-center justify-between gap-2 rounded-lg bg-surface px-5 py-4 xl:flex">
          <div>
            <div className="text-title-medium text-on-surface">
              {rubric.data.rubric}
            </div>
            <div className="text-body-small text-on-surface">
              {rubric.data.rubricDescription}
            </div>
          </div>
          <div className="mt-3 flex shrink-0 xl:mt-0">
            <RubricScoreDisplay
              rubricType={rubric.rubricType!}
              score={rubricResult.data.score}
            />
            <BreakoutButton
              className="ml-3 flex items-center justify-center !bg-core-tertiary"
              kind="secondary"
              onClick={() => {
                cubit.logEvent('rubric_result_more_details_clicked', {
                  rubric_id: rubric.id,
                  rubric_score: rubricResult.data.score,
                  rubric_type: rubric.rubricType,
                })
                if (cubit.inDialog) {
                  setRubricDataForInlineDialog({
                    rubricResults: rubricResult,
                    rubric,
                  })
                  return
                }

                showDialog(() => (
                  <RubricResultMoreDetailsDialog
                    cubit={cubit}
                    rubric={rubric}
                    rubricResult={rubricResult}
                    onSubmit={async ({ feedback }) => {
                      await cubit.submitFeedback(rubric.id, feedback)
                      cubit.logEvent('rubric_result_feedback_submitted', {
                        rubric_id: rubric.id,
                        rubric_score: rubricResult.data.score,
                        rubric_type: rubric.rubricType,
                        feedback: feedback,
                      })
                    }}
                    inline={false}
                  />
                ))
              }}
            >
              {t('meeting.more_details')}
              <ChevronRight size={15} className="ml-1" />
            </BreakoutButton>
          </div>
        </div>
        <div>
          <div>
            {rubricResult.data.arguments.length > 0 && (
              <div className="px-5">
                <EvaluationPoints points={rubricResult.data.arguments} />
              </div>
            )}
            {rubricResult.data.justification && (
              <div className="px-5">
                <Justification
                  justification={rubricResult.data.justification}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {rubricDataForInlineDialog && (
        <Portal>
          <RubricResultMoreDetailsDialog
            cubit={cubit}
            rubric={rubricDataForInlineDialog.rubric}
            rubricResult={rubricDataForInlineDialog.rubricResults}
            onSubmit={async () => {}}
            inline={true}
            closeDialog={() => setRubricDataForInlineDialog(undefined)}
          />
        </Portal>
      )}
    </>
  )
})

export const Justification = observer(function Justification({
  justification,
}: {
  justification: string
}) {
  const { t } = useTranslation()
  return (
    <div className="mt-4 flex">
      <SparklesIcon
        size={20}
        className="mr-1 flex-shrink-0 stroke-fixed-accent-color"
      />
      <div>
        <div className="text-title-small text-on-surface">
          {t('meeting.evaluation_summary')}
        </div>
        <div className="text-body-medium text-body-medium mt-1 text-on-surface">
          {justification}
        </div>
      </div>
    </div>
  )
})

export const EvaluationPoints = observer(function EvaluationPoints({
  points,
}: {
  points: string[]
}) {
  const { t } = useTranslation()
  return (
    <div className="mt-4 flex">
      <ThreeLineHorizontalIcon
        size={20}
        className="mr-1 flex-shrink-0 stroke-fixed-grey"
      />

      <div>
        <div className="text-title-small text-on-surface">
          {t('meeting.evaluation_points')}
        </div>
        <ul className="text-body-medium text-body-medium mt-1 list-disc pl-4 text-on-surface">
          {points.map((argument, index) => (
            <li key={index}>{argument}</li>
          ))}
        </ul>
      </div>
    </div>
  )
})

export const RubricScoreDisplay = observer(function RubricScoreDisplay({
  score,
  rubricType,
  className,
}: {
  rubricType: RubricType
  score: number
  className?: string
}) {
  const { t } = useTranslation()
  const scoreLabel = getRubricScoreLabel(t, rubricType, score)
  return (
    <div
      className={classNames(
        'text-label-small flex shrink-0 items-center justify-center rounded-xl bg-opacity-30 px-3',
        getRubricBgColorByScore({
          type: rubricType,
          score: score,
        }),
        className
      )}
    >
      {scoreLabel}
    </div>
  )
})

const Portal = ({ children }: { children: React.ReactNode }) => {
  return createPortal(children, document.body)
}
