import {
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
} from 'firebase/auth'
import { useCallback, useEffect, useState } from 'react'
import { useAuthContext, useRepository } from '../../hooks/auth'
import { DevLoginButton } from './DevLoginButton'
import type { SlideDeck } from '@breakoutlearning/firebase-repository/models/SlideDeck'
import type { SlideDeckMaterial } from '@breakoutlearning/firebase-repository/models/SlideDeckMaterial'
import { LoginCubit } from '@breakoutlearning/firebase-repository/cubits/LoginCubit'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { AnimatePresence, m } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { MultiLineText } from 'components/breakout/MultiLineText'
import { useCubitBuilder } from 'hooks/cubits'
import { useThrottledCallback } from 'hooks/functions'

export const LoginPage = observer(function Login() {
  const repository = useRepository()
  const authContext = useAuthContext()
  const [error, setError] = useState<string | null>()
  const { t } = useTranslation()
  const loginCubit = useCubitBuilder(
    () => new LoginCubit(repository),
    [repository]
  )
  const [showSlideDecks, setShowSlideDecks] = useState(false)

  const onLoginClick = useCallback(
    (useProvider: 'google' | 'microsoft') => {
      const provider =
        useProvider === 'google'
          ? new GoogleAuthProvider()
          : new OAuthProvider('microsoft.com')
      setError(null)
      signInWithPopup(repository.auth, provider).catch((error) => {
        setError(error.message)
      })
    },
    [repository]
  )

  useEffect(() => {
    const onResize = () => {
      const sufficientSizeForCarousel = window.innerWidth > 1300
      if (sufficientSizeForCarousel) {
        loginCubit.initSlideDecks()
      }
      setShowSlideDecks(sufficientSizeForCarousel)
    }
    window.addEventListener('resize', onResize)
    onResize()
    return () => window.removeEventListener('resize', onResize)
  }, [loginCubit])

  return (
    <div className="flex h-full w-full flex-row gap-8">
      <div
        className={classNames(
          'box-border flex h-full flex-row rounded-3xl bg-surface-bright px-10 py-11 md:w-full',
          { 'max-w-[600px]': showSlideDecks }
        )}
      >
        <div className="items-left flex h-full flex-col justify-between">
          <div>
            <img
              className="dark:invert"
              src="/assets/images/logo_large_trimmed.png"
              width={161}
            />
          </div>
          <div className="max-w-[600px] text-on-surface">
            <h3 className=" text-display-medium mb-4 font-bold leading-10 tracking-tighter">
              <MultiLineText string={t('login.title')} />
            </h3>
            <p className="text-body-large">{t('login.title_sub')}</p>
            <div>
              <div className="mt-6 flex flex-wrap gap-4">
                <button
                  className="text-label-large flex w-[250px] flex-row items-center justify-center rounded-[4px] bg-core-secondary px-1 py-[0.625rem]"
                  onClick={() => onLoginClick('google')}
                >
                  <img
                    className="mr-3 inline"
                    src="/assets/images/google.png"
                    width={30}
                    style={{ imageRendering: 'crisp-edges' }}
                  />
                  {t('login.google_login')}
                </button>
                <button
                  className="text-label-large flex w-[250px] flex-row items-center justify-center rounded-[4px] bg-core-secondary px-1 py-[0.625rem]"
                  onClick={() => onLoginClick('microsoft')}
                >
                  <img
                    className="mr-3 inline"
                    src="/assets/images/microsoft.png"
                    width={30}
                    style={{ imageRendering: 'crisp-edges' }}
                  />
                  {t('login.microsoft_login')}
                </button>
              </div>
              {error && <p className="mt-4 text-breakout-red">{error}</p>}
            </div>
            {authContext.usingLocalEmulator && (
              <div className="mt-6 flex flex-row flex-wrap items-center justify-start gap-2">
                <div>Development login as</div>
                <DevLoginButton role="admin" />
                <DevLoginButton role="instructor" />
                <DevLoginButton role="student" />
                <DevLoginButton role="ta" />
              </div>
            )}
          </div>
          <div>
            <span className="text-label-medium">
              {t('login.login_terms')}{' '}
              <a
                className="text-title-small"
                target="_blank"
                href="https://breakoutlearning.com/legal/terms-of-use"
                rel="noreferrer"
              >
                {t('login.terms_and_conditions')}
              </a>
            </span>
          </div>
        </div>
      </div>
      {showSlideDecks && loginCubit.slideDecksWithMaterials.length > 0 && (
        <SlideDeckCarousel cubit={loginCubit} />
      )}
    </div>
  )
})

const SlideDeckCarousel = observer(function SlideDeckCarousel({
  cubit,
}: {
  cubit: LoginCubit
}) {
  const [currentSlideDeckIndex, setCurrentSlideDeckIndex] = useState(0)
  const slideDeckWithMaterials =
    cubit.slideDecksWithMaterials[currentSlideDeckIndex]
  const animationDuration = 2

  const nextSlide = useThrottledCallback(
    () => {
      setCurrentSlideDeckIndex((index) => {
        const nextIndex = index + 1
        const nextIndexOrStart =
          nextIndex >= cubit.slideDecksWithMaterials.length ? 0 : nextIndex

        return nextIndexOrStart
      })
    },
    animationDuration * 1000,
    [cubit.slideDecksWithMaterials]
  )

  useEffect(() => {
    const interval = setInterval(nextSlide, 5000)
    return () => clearInterval(interval)
  }, [nextSlide, currentSlideDeckIndex])

  return (
    <div className="relative h-full w-full">
      <AnimatePresence key="foo" initial={true} mode="sync">
        <m.div
          key={currentSlideDeckIndex.toString()}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: animationDuration }}
          className="absolute inset-0 left-0 top-0 h-full w-full "
        >
          <SlideDeckCarouselInner
            slideDeckWithMaterial={slideDeckWithMaterials}
            onClick={nextSlide}
            currentSlideDeckIndex={currentSlideDeckIndex}
            slideCount={cubit.slideDecksWithMaterials.length}
          />
        </m.div>
      </AnimatePresence>
    </div>
  )
})

const SlideDeckCarouselInner = ({
  slideDeckWithMaterial,
  onClick,
  currentSlideDeckIndex,
  slideCount,
}: {
  slideDeckWithMaterial: {
    slideDeck: SlideDeck
    material: SlideDeckMaterial
  }
  currentSlideDeckIndex: number
  slideCount: number
  onClick?: () => void
}) => {
  const { slideDeck, material } = slideDeckWithMaterial

  const materialLink = material.data.materialLink

  return (
    <div
      style={{ backgroundImage: `url(${materialLink})` }}
      className={classNames(
        'flex h-full w-full place-items-end overflow-hidden rounded-3xl bg-cover bg-center bg-no-repeat p-10',
        { 'cursor-pointer': !!onClick && slideCount > 1 }
      )}
      onClick={onClick}
    >
      <div
        className={classNames('flex w-full flex-row place-items-end', {
          'justify-between': slideCount > 1,
          'justify-end': slideCount === 1,
        })}
      >
        <CarouselTabs
          activeTab={currentSlideDeckIndex}
          totalTabs={slideCount}
        />
        <div className="flex flex-col place-items-end justify-end">
          <div className="mb-[9px] flex flex-row">
            <img
              src={slideDeck.data.slideDeckImageURL}
              className="mr-1 h-5 object-contain"
            />
            <h2 className="text-title-large text-core-tertiary">
              {slideDeck.data.slideDeckName}
            </h2>
          </div>
          <h3 className="text-headline-medium text-core-tertiary">
            {slideDeck.data.slideDeckTeaser}
          </h3>
        </div>
      </div>
    </div>
  )
}

const CarouselTabs = ({
  activeTab,
  totalTabs,
}: {
  activeTab: number
  totalTabs: number
}) => {
  if (totalTabs < 2) return null
  return (
    <div className="flex flex-row justify-center gap-1">
      {Array.from({ length: totalTabs }).map((_, i) => (
        <div
          key={i}
          className={classNames('h-0.5 w-[22px] rounded-[100px]', {
            'bg-fixed-grey': activeTab !== i,
            'bg-core-secondary': activeTab === i,
          })}
        />
      ))}
    </div>
  )
}
