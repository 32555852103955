import { observer } from 'mobx-react-lite'
import { GraphBarIcon } from 'components/icons/GraphBar'
import { DateTime } from 'luxon'
import { memo } from 'react'
import { LightBulbIcon } from 'components/icons/LightBulb'
import { useTranslation } from 'react-i18next'
import { QuestionIcon } from 'components/icons/Question'
import classNames from 'classnames'
import type { MeetingResultsSlide } from '@breakoutlearning/firebase-repository/cubits/meeting/slides/MeetingResultsSlide'
import { LazyMarkdown } from 'components/LazyMarkdown'

const SummaryView = observer(function SummaryView({
  slide,
}: {
  slide: MeetingResultsSlide
}) {
  const { t } = useTranslation()
  if (!slide.meeting.roomStateSummary.hasData) {
    return (
      <div className="text-body-large mt-3 text-grey-text">
        <p>{t('summary.our_servers_are_busy')}</p>
      </div>
    )
  }

  return (
    <div className="react-markdown mt-5">
      <LazyMarkdown>{slide.meeting.roomStateSummary.data.summary}</LazyMarkdown>
    </div>
  )
})

const FinishedAt = memo(function FinishedAt({
  timestamp,
}: {
  timestamp: Date | null
}) {
  if (!timestamp) return null

  return (
    <div className="text-body-medium text-right">
      Finished at{' '}
      {DateTime.fromJSDate(timestamp).toLocaleString(DateTime.DATETIME_FULL)}
    </div>
  )
})

export const SummaryDecoration = observer(function SummaryWithDecoration({
  roomStateActiveSlideChangedAt,
  roomStateName,
  children,
}: {
  roomStateActiveSlideChangedAt: Date | null
  roomStateName: string | null
  children: React.ReactNode
}) {
  const { t } = useTranslation()
  return (
    <div className="flex flex-1 flex-col justify-between rounded-2xl">
      <div>
        <div className="flex flex-row items-center p-5">
          <GraphBarIcon className="mr-1" color="green" size={15} />
          <span className="text-label-small">{t('summary.ai_summary')}</span>
        </div>
        <div className="flex flex-row justify-between px-10">
          <div className="text-label-large">{roomStateName}</div>
          <FinishedAt timestamp={roomStateActiveSlideChangedAt} />
        </div>
      </div>
      <div className="flex flex-1 flex-col justify-start overflow-auto px-10">
        {/* summary view place children here*/}
        {children}
      </div>
      <Hint className="mx-10 mt-6  pb-6" text={t('summary.summary_hint')} />
    </div>
  )
})

const Hint = memo(function Hint({
  text,
  className,
}: {
  className?: string
  text: string
}) {
  return (
    <div
      className={classNames(
        'flex flex-row items-center border-t border-medium-grey pt-3 text-grey-text',
        className
      )}
    >
      <div className="mx-1 flex-none">
        <LightBulbIcon size={15} />
      </div>
      <div className="text-body-small line-clamp-3">{text}</div>
    </div>
  )
})

const ResultsBox = memo(function ResultsBox({
  type,
  value,
}: {
  type: 'quizPerformance' | 'pollAgreement'
  value: number
}) {
  const { t } = useTranslation()

  return (
    <div className="flex h-full flex-col justify-between rounded-2xl border border-gray-200 p-5">
      <div className="flex flex-row">
        {type === 'quizPerformance' && (
          <>
            <QuestionIcon className="mr-1 stroke-breakout-red" size={15} />
            <span className="text-label-small">
              {t('summary.quiz_performance')}
            </span>
          </>
        )}
        {type === 'pollAgreement' && (
          <>
            <GraphBarIcon className="mr-1" color="green" size={15} />
            <span className="text-label-small">
              {t('summary.poll_agreement')}
            </span>
          </>
        )}
      </div>
      <div
        className="flex flex-1 flex-col items-center justify-center"
        data-testid={`percentage-${type}`}
      >
        <div>
          <span className="text-display-medium">{Math.round(value * 100)}</span>
          <span className="text-headline-medium">%</span>
        </div>
      </div>
      <Hint
        text={
          type === 'quizPerformance'
            ? t('summary.quiz_performance_hint')
            : t('summary.poll_agreement_hint')
        }
      />
    </div>
  )
})

export const MeetingResultsSlideView = observer(
  function MeetingResultsSlideView({ slide }: { slide: MeetingResultsSlide }) {
    const meeting = slide.meeting

    return (
      <div
        data-testid="meeting-results-view"
        className="flex h-full w-full flex-col gap-5 p-5 sm:flex-row"
      >
        <div className="flex flex-col justify-evenly gap-5 md:w-[250px]">
          <ResultsBox
            type="quizPerformance"
            value={meeting.sessionResults.quizPerformance}
          />
          <ResultsBox
            type="pollAgreement"
            value={meeting.sessionResults.pollAgreement}
          />
        </div>
        <SummaryDecoration
          roomStateActiveSlideChangedAt={meeting.activeSlideChangedAt}
          roomStateName={meeting.roomState.roomStateName}
        >
          <SummaryView slide={slide} />
        </SummaryDecoration>
      </div>
    )
  }
)
