import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { useTranslation } from 'react-i18next'
import { Button } from 'components/Button'
import { observer } from 'mobx-react-lite'
import { MultiLineText } from 'components/breakout/MultiLineText'

export const StartSessionDialog = observer(function StartSessionDialog({
  onConfirmStartSession,
}: {
  onConfirmStartSession: VoidFunction
}) {
  const { t } = useTranslation()

  return (
    <Dialog testId="start-session-dialog" size="xs">
      <DialogCloseButton />
      <div className="flex flex-col text-center">
        <h1 className="text-headline-large mb-3">
          {t('student_assignment.start_session')}
        </h1>
        <p className="text-body-large mb-8">
          <MultiLineText
            string={t('student_assignment.start_session_confirmation')}
          />
        </p>
        <Button
          testId="start-session-dialog-button"
          className="w-full bg-core-primary text-core-on-primary"
          onClick={onConfirmStartSession}
        >
          {t('student_assignment.start_session')}
        </Button>
      </div>
    </Dialog>
  )
})
