import classNames from 'classnames'
import { Spinner } from 'components/Spinner'
import {
  BreakoutIconButton,
  type ButtonKind,
} from 'components/design-system/BreakoutButton'
import { observer } from 'mobx-react-lite'
import React, {
  isValidElement,
  useCallback,
  useEffect,
  useState,
  cloneElement,
} from 'react'

type Props = React.HtmlHTMLAttributes<HTMLDivElement>

export const FloatingIconActionButton = observer(function FloatingActionButton({
  className,
  buttonClassName,
  actions,
  Icon,
  kind,
  menuBottom,
  'aria-label': ariaLabel,
  ...rest
}: Props & {
  Icon: React.ComponentType<{ size: number }>
  actions: React.ReactNode | Array<MenuButtonProps>
  kind?: ButtonKind
  buttonClassName?: string
  'aria-label'?: string
  menuBottom?: boolean
}) {
  const [menuVisible, setMenuVisible] = useState(false)

  const onClickHideMenu = useCallback(() => {
    setMenuVisible(false)
  }, [])

  const toggleMenuVisible = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation()
    setMenuVisible(!menuVisible)
  }

  useEffect(() => {
    if (menuVisible) {
      return window.addEventListener('click', onClickHideMenu)
    }
    window.removeEventListener('click', onClickHideMenu)

    return () => {
      window.removeEventListener('click', onClickHideMenu)
    }
  }, [menuVisible, onClickHideMenu])

  return (
    <div {...rest} className={classNames('relative', className)}>
      <BreakoutIconButton
        kind={kind}
        role="button"
        aria-label={ariaLabel}
        aria-haspopup="true"
        onClick={(e) => toggleMenuVisible(e)}
        icon={<Icon aria-hidden size={24} />}
        className={classNames(
          '!rounded-full border-none shadow-sm',
          buttonClassName
        )}
      />
      {menuVisible && (
        <RenderChildren menuBottom={menuBottom || false}>
          {actions}
        </RenderChildren>
      )}
    </div>
  )
})

type MenuButtonProps = {
  Icon: React.ComponentType<{ size: number }>
  text: string
  onClick: () => void
  isLoading?: boolean
}

const MenuButton = ({ Icon, text, onClick, isLoading }: MenuButtonProps) => {
  return (
    <button
      onClick={!isLoading ? onClick : undefined}
      className="flex cursor-pointer flex-row items-center gap-1 px-4 py-3 hover:bg-surface"
    >
      {isLoading ? (
        <Spinner aria-hidden size={1.5} />
      ) : (
        <Icon aria-hidden size={24} />
      )}
      <span className="text-label-medium">{text}</span>
    </button>
  )
}

const RenderChildren = ({
  children,
  menuBottom,
}: {
  children: React.ReactNode | Array<MenuButtonProps>
  menuBottom: boolean
}) => {
  if (!Array.isArray(children)) {
    if (!isValidElement(children)) return null
    return cloneElement(children, {
      className: `${children.props.className || ''} absolute ${menuBottom ? 'top-full mt-2' : 'bottom-full mb-2'}`,
    } as Partial<unknown>) // Add the 'Attributes' type to the type definition
  }
  // render menu buttons
  return (
    <div
      role="menu"
      className={`absolute ${menuBottom ? 'top-full mt-2' : 'bottom-full mb-2'} flex flex-col text-nowrap rounded-lg bg-core-tertiary py-2 shadow-3xl`}
    >
      {children.map((child, index) => (
        <MenuButton key={index} {...child} />
      ))}
    </div>
  )
}
