import { RubricType } from '@breakoutlearning/firebase-repository/models/SlideRubric'
import { Spinner } from 'components/Spinner'
import { EmptyState } from 'components/breakout/EmptyState'
import { ChevronRight } from 'components/icons/ChevronRight'
import { Search } from 'components/icons/Search'
import { useInstructorAssignmentCubit } from 'hooks/cubits/instructorAssignment'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { PassFailRubricChart } from '../rubric_components/PassFailRubricChart.'
import { BloomsRubricChart } from '../rubric_components/BloomsRubricChart'
import { useMemo } from 'react'
import { BreakoutUserAvatar } from 'components/breakout/BreakoutUserAvatar'
import classNames from 'classnames'
import type { RubricResultWithUser } from '@breakoutlearning/firebase-repository/cubits/InstructorAssignmentCubit'

export const SummaryRubricAssessment = observer(
  function SummaryRubricAssessment() {
    const cubit = useInstructorAssignmentCubit()
    const { t } = useTranslation()

    const rubricsList = useMemo(
      () => Array.from(cubit.rubrics.entries()),
      [cubit.rubrics]
    )

    // show spinner if cubit.rubricResultsDataIsLoading is true
    if (cubit.rubricResultsDataIsLoading) {
      return (
        <div className="flex w-full flex-col items-center justify-center md:h-full">
          <Spinner />
        </div>
      )
    }

    if (cubit.rubrics.size === 0) {
      return (
        <EmptyState
          Icon={Search}
          text={t('instructor_assignment.results_missing')}
        />
      )
    }

    return (
      <>
        <div className="mb-4 flex items-center justify-between border-b border-border-grey pb-4">
          <div>
            <h1 className="text-title-large">
              {t('instructor_assignment.rubric_assessment')}
            </h1>
            <div className="text-body-medium text-grey-text">
              {t('instructor_assignment.n_captured_responses', {
                count: cubit.rubricResponseCount,
              })}
            </div>
          </div>
          <div
            className="text-body-medium flex cursor-pointer items-center justify-between text-grey-text"
            onClick={() => {
              cubit.changeResultsTab('rubric')
              cubit.changeTab('results')
            }}
          >
            {t('instructor_assignment.view_details')}
            <span className="text-grey-text">
              <ChevronRight size={14} />
            </span>
          </div>
        </div>
        <div className="">
          <div className="flex h-full w-full flex-col">
            {rubricsList.map(([slideRubric, rubricResults]) => {
              return (
                <div className="py-4" key={slideRubric.id}>
                  {slideRubric.rubricType === RubricType.passFail ? (
                    <PassFailRubricChart
                      key={slideRubric.id}
                      results={rubricResults}
                      rubric={slideRubric}
                      roomStates={cubit.roomStates.models}
                      section={cubit.section}
                      usersMap={cubit.usersMap}
                    />
                  ) : (
                    <BloomsRubricChart
                      key={slideRubric.id}
                      results={rubricResults}
                      rubric={slideRubric}
                      roomStates={cubit.roomStates.models}
                      section={cubit.section}
                      usersMap={cubit.usersMap}
                    />
                  )}
                  <div className="flex justify-center border-b py-8">
                    <div className="flex h-[150px] w-full divide-x-2 divide-medium-grey">
                      {rubricResults.slice(0, 3).map((rubricResult, index) => (
                        <div
                          key={`${index}-${rubricResult.id}-${rubricResult.user.id}`}
                          className={classNames(
                            'w-1/3 px-4',
                            index === 0 ? '' : 'border-l'
                          )}
                        >
                          <UserRubricCard rubricResult={rubricResult} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </>
    )
  }
)

const UserRubricCard = observer(function UserRubricCard({
  rubricResult,
}: {
  rubricResult: RubricResultWithUser
}) {
  return (
    <div>
      <div className="flex items-center space-x-2">
        <BreakoutUserAvatar user={rubricResult.user} radius={14} />
        <span className="text-body-medium">{rubricResult.user.fullName}</span>
      </div>
      <div className="text-body-medium line-clamp-6">
        {rubricResult.data.justification}
      </div>
    </div>
  )
})
