import { GraphqlIcon } from 'components/icons/Graphql'
import { Spinner } from 'components/Spinner'
import { useMeetingResultsCubit } from 'hooks/cubits/meetingsResults'
import { observer } from 'mobx-react-lite'
import { lazy, Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { useWaitFor } from './use-wait-for'
const ConversationMapAnimatedGraphBucketed = lazy(
  () => import('./ConversationMapAnimatedGraphBucketed')
)

export const ConversationMap = observer(function ConversationMap() {
  const { t } = useTranslation()
  const cubit = useMeetingResultsCubit()
  const waitedFor3Min = useWaitFor(3)

  if (cubit.conversationMapDataLoading) {
    return <Spinner />
  }
  const data = cubit.conversationMapData
  const bucketsEmpty = !data?.buckets.some((bucket) => bucket.length > 0)

  // If buckets are empty and AI is pending and we have not waited for 5 minutes
  // then show the loading spinner.
  if (bucketsEmpty && cubit.aiStatus === 'pending' && !waitedFor3Min) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center gap-1 text-center">
        <Spinner size={1.75} />
        <h2 className="text-title-large">{t('meeting.conversation_map')}</h2>
        <span className="text-body-medium">
          {t('meeting.conversation_map_loading')}
        </span>
      </div>
    )
  }

  // If buckets are empty and AI is not pending or we have waited for 5 minutes
  // then show the message that there is no data available
  if (bucketsEmpty && (cubit.aiStatus !== 'pending' || waitedFor3Min)) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center gap-1 text-center">
        <GraphqlIcon size={28} gTagClassName="fill-fixed-accent-color" />
        <h2 className="text-title-large">{t('meeting.conversation_map')}</h2>
        <span className="text-body-medium">
          {t('meeting.conversation_map_no_results')}
        </span>
      </div>
    )
  }

  return (
    <>
      <div className="text-title-large">{t('meeting.conversation_map')}</div>
      <div className="text-body-medium">
        {t('meeting.conversation_map_description')}
      </div>
      <div
        className="mt-4"
        data-testid="meeting-results-conversation-map"
        data-sentry-block
      >
        <Suspense fallback={<div>Loading...</div>}>
          <ConversationMapAnimatedGraphBucketed data={data!} />
        </Suspense>
      </div>
    </>
  )
})
