import { useRepository } from './auth'
import type { BreakoutUser } from '@breakoutlearning/firebase-repository/models/BreakoutUser'
import { UserContext } from './contexts'
import { observer } from 'mobx-react-lite'
import { FullPageSpinner } from 'components/Spinner'

/// passedProfile can be used for testing purposes
export const UserContextProvider = observer(function BreakoutUser({
  children,
  passedUser,
}: {
  children: React.ReactNode
  passedUser?: BreakoutUser
}) {
  const repo = useRepository()

  const user = passedUser || repo.breakoutUser

  if (!user || user.isLoading || !user.hasData) return <FullPageSpinner />

  return (
    <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
  )
})
