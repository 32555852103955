import { UserProfileRole } from '@breakoutlearning/firebase-repository/types'
import { Breadcrumb } from 'components/Breadcrumb'
import { LibraryObjectBadge } from 'components/LibraryObjectBadge'
import { useStudentAssignmentCubit } from 'hooks/cubits/studentAssignment'
import { useBreakoutUser } from 'hooks/profile'
import { useRootStore } from 'hooks/rootStore'
import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'

export const StudentAssignmentHeader = observer(
  function StudentAssignmentHeader() {
    const cubit = useStudentAssignmentCubit()
    const store = useRootStore()
    const { role } = useBreakoutUser()

    const onClick = useCallback(() => {
      if (role === UserProfileRole.instructor) {
        return store.navigateTo('instructorStudentView')
      }
      store.navigateTo('home')
    }, [role, store])

    return (
      <div className="mb-3 flex flex-grow-0 flex-col items-center justify-between  md:flex-row">
        <Breadcrumb onClick={onClick}>
          {cubit.slideDeck.data.slideDeckName} |{' '}
          {cubit.slideDeck.data.slideDeckTeaser}
        </Breadcrumb>
        <div className="mt-3 md:mt-0">
          <LibraryObjectBadge libraryObject={cubit.libraryObject} />
        </div>
      </div>
    )
  }
)
