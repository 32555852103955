import type {
  StudentLibraryCubit,
  StudentLibraryAssignmentFilterType,
  StudentLibraryAssignmentSortingStrategy,
  StudentLibraryAssignmentSortingOrder,
} from '@breakoutlearning/firebase-repository/cubits/StudentLibraryCubit'
import {
  ValidLibraryObjectActionState,
  type ValidLibraryObject,
} from '@breakoutlearning/firebase-repository/stores/ValidLibraryObject'
import classNames from 'classnames'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { BreakoutSelect } from 'components/design-system/BreakoutSelect'
import { BreakoutTable } from 'components/design-system/BreakoutTable'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { ChevronRight } from 'components/icons/ChevronRight'
import { CircleX } from 'components/icons/CircleX'
import { ClockIcon } from 'components/icons/Clock'
import { Search } from 'components/icons/Search'
import { SortIcon } from 'components/icons/Sort'
import { TriangleRightFillIcon } from 'components/icons/TriangleRightFIll'
import { Spinner } from 'components/Spinner'
import { useRootStore } from 'hooks/rootStore'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { useCallback, type MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { EmptyListPlaceholder } from './EmptyListPlaceholder'

// passing current strategy explicity or else it will not re-render on change
const SortableHeaderLabel = ({
  cubit,
  label,
  currentStrategy,
  sortingStrategy,
  currentOrder,
}: {
  cubit: StudentLibraryCubit
  label: string
  currentOrder: StudentLibraryAssignmentSortingOrder
  currentStrategy: StudentLibraryAssignmentSortingStrategy
  sortingStrategy: StudentLibraryAssignmentSortingStrategy
}) => {
  const isCurrentStrategy = currentStrategy === sortingStrategy
  return (
    <th className="!rounded-t-none !pt-7">
      <div
        className="flex cursor-pointer flex-row items-center gap-1"
        onClick={() => {
          if (isCurrentStrategy && currentOrder === 'desc') {
            // switch back to default sorting
            cubit.changeSortingStrategy('default', 'asc')
          } else if (isCurrentStrategy) {
            // switch order
            cubit.changeSortingStrategy(sortingStrategy, 'desc')
          } else {
            // switch strategy and order
            cubit.changeSortingStrategy(sortingStrategy, 'asc')
          }
        }}
      >
        <span
          className={classNames({
            'text-label-medium text-on-surface-var': !isCurrentStrategy,
            'text-body-medium text-on-surface': isCurrentStrategy,
          })}
        >
          {label}
        </span>
        <TriangleRightFillIcon
          size={12}
          className={classNames('rotate-90', {
            'rotate-90': isCurrentStrategy && currentOrder === 'asc',
            '!rotate-270': isCurrentStrategy && currentOrder === 'desc',
            'fill-on-surface-disabled': !isCurrentStrategy,
            'fill-fixed-accent-color': isCurrentStrategy,
          })}
        />
      </div>
    </th>
  )
}

const TableRow = ({
  cubit,
  libraryObject,
  isLast,
}: {
  cubit: StudentLibraryCubit
  libraryObject: ValidLibraryObject
  isLast: boolean
}) => {
  const getFormattedTime = (date: Date) => {
    const dayStr = new Intl.DateTimeFormat('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    }).format(date)
    const timeStr =
      DateTime.fromJSDate(date, { zone: 'local' })
        .setZone(DateTime.local().zoneName)
        .toLocaleString(DateTime.TIME_SIMPLE) +
      ' ' +
      DateTime.local().toFormat('ZZZZ')
    return {
      dayStr,
      timeStr,
    }
  }
  const { expiresAt } = libraryObject.assignment
  const { scheduledAt } = libraryObject.roomState.data

  const expiresAtFormatted = expiresAt ? getFormattedTime(expiresAt) : null
  const scheduledAtFormatted = scheduledAt
    ? getFormattedTime(scheduledAt)
    : null

  const { navigateTo } = useRootStore()
  const canNavigateToAssignmentPage =
    !libraryObject.assignment.assignedAtIsInFuture

  return (
    <tr
      data-testid={`library-object-list-item-${libraryObject.libraryObjectId}`}
      className={classNames('!border-surface bg-surface-bright', {
        '!border-b-8': !isLast,
        '!border-none': isLast,
        'cursor-pointer': canNavigateToAssignmentPage,
      })}
      onClick={
        canNavigateToAssignmentPage
          ? () => {
              const roomStateId = libraryObject.roomState.id
              const route = roomStateId ? 'assignmentWithRoom' : 'assignment'
              navigateTo(route, {
                assignmentId: libraryObject.assignment.id,
                sectionId: libraryObject.section.id,
                roomStateId: libraryObject.roomState.id,
              })
            }
          : undefined
      }
    >
      {/* course (section/instructor) */}
      <TableRowClassNameCell libraryObject={libraryObject} />
      {/* experience (slide deck logo/name/teaser) */}
      <td>
        <div className="flex flex-row items-center">
          <div className="aspect-square h-10 p-2">
            {libraryObject.slideDeckImageURL ? (
              <img
                src={libraryObject.slideDeckImageURL}
                className="max-h-full max-w-full object-cover"
              />
            ) : (
              <div className="h-full w-full bg-surface-dim" />
            )}
          </div>
          <div className="flex flex-col">
            <span className="text-body-medium line-clamp-1 leading-[1.05rem] text-on-surface-var">
              {libraryObject.slideDeckName}
            </span>
            <span className="text-label-large line-clamp-1 leading-[1.05rem] text-on-surface">
              {libraryObject.slideDeckTeaser}
            </span>
          </div>
        </div>
      </td>
      {/* due date */}
      <td className="!pl-5">
        {expiresAtFormatted && (
          <div className="flex flex-col">
            <span className="text-label-medium">
              {expiresAtFormatted.dayStr}
            </span>
            <span className="text-body-medium text-surface-on-surface-var">
              {expiresAtFormatted.timeStr}
            </span>
          </div>
        )}
      </td>
      {/* scheduledDate */}
      <td className="!pl-5">
        {scheduledAtFormatted && (
          <div className="flex flex-col">
            <span className="text-label-medium">
              {scheduledAtFormatted.dayStr}
            </span>
            <span className="text-body-medium text-surface-on-surface-var">
              {scheduledAtFormatted.timeStr}
            </span>
          </div>
        )}
      </td>
      {/* action (todo: some complex function from library obj) */}
      <td
        className="!pl-5"
        style={{
          borderRadius: '0 16px 16px 0',
        }}
      >
        {/* pr-2 prevents the outline from peeking from under the sticky header */}
        <div className="flex h-full w-full items-center justify-center pr-2">
          <ActionForLibraryObject
            cubit={cubit}
            libraryObject={libraryObject}
            isHeader={false}
          />
        </div>
      </td>
    </tr>
  )
}

const TableRowClassNameCell = observer(function TableRowClassNameCell({
  libraryObject,
}: {
  libraryObject: ValidLibraryObject
}) {
  return (
    <td
      style={{
        borderRadius: '16px 0 0 16px',
      }}
      className="!pl-5"
    >
      <div className="flex flex-col justify-center">
        <span className="text-label-medium">
          {libraryObject.section.data.className}
        </span>
        <span className="text-body-medium">
          {libraryObject.section.instructor.fullName}
        </span>
      </div>
    </td>
  )
})

export const AssignmentTable = observer(function AssignmentTable({
  cubit,
}: {
  cubit: StudentLibraryCubit
}) {
  const { t } = useTranslation()

  if (cubit.libraryObjects.length === 0) return <EmptyListPlaceholder />

  const libraryObjectList = cubit.filteredSortedLibraryObjects

  return (
    <div className="flex-grow overflow-auto rounded-2xl bg-surface px-7 pb-7">
      <BreakoutTable
        className={classNames('relative table-fixed', {
          'min-h-full': libraryObjectList.length === 0,
        })}
      >
        <thead className="sticky top-0 z-10 bg-surface">
          <tr>
            <td colSpan={5}>
              <div className="flex w-full flex-row gap-4 pt-7">
                <BreakoutTextInput
                  autoFocus
                  name="filter"
                  onChange={(value) => {
                    cubit.changeSearchTerm(value.target.value)
                  }}
                  LeadingIcon={Search}
                  className="flex-grow"
                  inputClassName="text-body-medium border font-medium placeholder-surface-on-surface-disabled"
                  kind="tertiary"
                  placeholder={t('library.search_experiences')}
                  clearIcon
                />
                <FilterAssignmentsButton cubit={cubit} />
              </div>
            </td>
          </tr>
          <tr>
            <SortableHeaderLabel
              cubit={cubit}
              label={t('library.course')}
              sortingStrategy={'sectionName'}
              currentStrategy={cubit.sortingStrategy}
              currentOrder={cubit.sortingOrder}
            />
            <SortableHeaderLabel
              cubit={cubit}
              label={t('library.experience')}
              sortingStrategy={'experienceName'}
              currentStrategy={cubit.sortingStrategy}
              currentOrder={cubit.sortingOrder}
            />
            <SortableHeaderLabel
              cubit={cubit}
              label={t('library.due_date')}
              sortingStrategy={'expiresAt'}
              currentStrategy={cubit.sortingStrategy}
              currentOrder={cubit.sortingOrder}
            />
            <SortableHeaderLabel
              cubit={cubit}
              label={t('library.session_date')}
              sortingStrategy={'scheduledAt'}
              currentStrategy={cubit.sortingStrategy}
              currentOrder={cubit.sortingOrder}
            />
            <th className="text-body-medium !rounded-t-none !pt-7 text-on-surface-var">
              <div className="w-full text-center">{t('library.action')}</div>
            </th>
          </tr>
        </thead>
        <tbody data-testid="library-object-list" className="overflow-y-auto">
          {libraryObjectList.length === 0 && <EmptyListState />}
          {libraryObjectList.map((libraryObject, i) => (
            <TableRow
              cubit={cubit}
              key={libraryObject.libraryObjectId}
              libraryObject={libraryObject}
              isLast={i === libraryObjectList.length - 1}
            />
          ))}
        </tbody>
      </BreakoutTable>
    </div>
  )
})

function EmptyListState() {
  const { t } = useTranslation()
  return (
    <tr>
      <td colSpan={5}>
        <div className="flex flex-col items-center justify-center gap-2 rounded-2xl bg-surface p-7">
          <Search className="stroke-fixed-accent-color" size={50} />
          <div className="text-title-large my-1 text-on-surface">
            {t('instructor_library.no_results_found')}
          </div>
          <div className="text-body-large text-center text-on-surface-var">
            {t('instructor_library.no_results_found_description_1')}
          </div>
        </div>
      </td>
    </tr>
  )
}

const Label = ({
  text,
  type,
}: {
  text: string
  type: StudentLibraryAssignmentFilterType
}) => {
  return (
    <div
      className={classNames(
        'text-label-medium flex flex-row items-center gap-1 whitespace-nowrap px-4 py-2'
      )}
    >
      {type !== 'all' && (
        <div
          className={classNames('h-2 min-h-2 w-2 min-w-2 rounded-full', {
            'bg-fixed-grey': type === 'future',
            'bg-fixed-orange': type === 'current',
            'bg-core-error': type === 'past',
          })}
        ></div>
      )}
      <span>{text}</span>
    </div>
  )
}

const SelectedLabel = ({ text }: { text: string }) => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-row items-center justify-center gap-4">
      <span>{t('library.filter_prefix', { text })}</span>
      <SortIcon size={15} />
    </div>
  )
}

const FilterAssignmentsButton = observer(function FilterAssignmentsButton({
  cubit,
}: {
  cubit: StudentLibraryCubit
}) {
  const { t } = useTranslation()

  // todo(ashold12): the floating container is not expanding not sure how to
  //disable width constraints but also don't want to dig into it at the moment
  return (
    <BreakoutSelect<StudentLibraryAssignmentFilterType>
      className="!min-w-[215px]"
      kind="tertiary"
      value={cubit.assignmentFilter}
      onChange={(value) => cubit.changeAssignmentFilter(value)}
      showChevron={false}
      options={[
        {
          label: <Label text={t('library.all_assignments')} type="all" />,
          selectedLabel: <SelectedLabel text={t('library.all_assignments')} />,
          value: 'all',
        },
        {
          label: <Label text={t('library.future_assignments')} type="future" />,
          selectedLabel: (
            <SelectedLabel text={t('library.future_assignments')} />
          ),

          value: 'future',
        },
        {
          label: <Label text={t('library.open_assignments')} type="current" />,
          selectedLabel: <SelectedLabel text={t('library.open_assignments')} />,
          value: 'current',
        },
        {
          label: <Label text={t('library.past_assignments')} type="past" />,
          selectedLabel: <SelectedLabel text={t('library.past_assignments')} />,
          value: 'past',
        },
      ]}
    />
  )
})

const ActionForLibraryObject = observer(function ActionForLibraryObject({
  cubit,
  libraryObject,
  isHeader,
}: {
  cubit: StudentLibraryCubit
  libraryObject: ValidLibraryObject
  isHeader: boolean
}) {
  const store = useRootStore()
  const { t } = useTranslation()
  const quizDataRequired =
    libraryObject.roomState.quizDataRequiredForActionState

  const navigateWithAction = useCallback(
    (
      actionState: ValidLibraryObjectActionState,
      event: MouseEvent<HTMLButtonElement>
    ) => {
      event.stopPropagation()
      const roomStateId = libraryObject.roomState.id
      const route = roomStateId ? 'assignmentWithRoom' : 'assignment'
      store.navigateTo(
        route,
        {
          assignmentId: libraryObject.assignment.id,
          sectionId: libraryObject.section.id,
          roomStateId: libraryObject.roomState.id,
        },
        {
          action: actionState.toString(),
        }
      )
    },
    [libraryObject, store]
  )

  // early return if quiz data is required and still loading
  if (quizDataRequired) {
    const quizData = cubit.quizDataForRoomState(libraryObject.roomState)
    if (quizData.questions.isLoading || quizData.answers.isLoading)
      return <Spinner />
  }

  const { questions, answers } = quizDataRequired
    ? (() => {
        const quizData = cubit.quizDataForRoomState(libraryObject.roomState)
        return {
          questions: quizData.questions.models,
          answers: quizData.answers.models,
        }
      })()
    : { questions: [], answers: [] }

  const actionState = libraryObject.getActionState(questions, answers)

  switch (actionState) {
    case ValidLibraryObjectActionState.enroll: {
      return (
        <BreakoutButton
          size={!isHeader ? 'small' : 'medium'}
          onClick={(e) => {
            navigateWithAction(ValidLibraryObjectActionState.enroll, e)
          }}
        >
          {t('library.actions.enroll', {
            dollars: libraryObject.slideDeck.priceInDollars,
          })}
        </BreakoutButton>
      )
    }
    case ValidLibraryObjectActionState.availableOn: {
      return (
        <div className="flex flex-col items-center justify-center gap-1">
          <span className="text-label-medium">{t('library.available_on')}</span>
          <span className="text-body-medium text-on-surface-var">
            {/* MM/DD/YY */}
            {libraryObject.assignment.data.assignedAt.toLocaleDateString()}
          </span>
        </div>
      )
    }
    case ValidLibraryObjectActionState.joinGroup: {
      return (
        <BreakoutButton
          kind={isHeader ? 'primary' : 'secondary'}
          size={!isHeader ? 'small' : 'medium'}
          onClick={(e) => {
            navigateWithAction(actionState, e)
          }}
        >
          {t('library.actions.join_group')}
        </BreakoutButton>
      )
    }
    case ValidLibraryObjectActionState.scheduleSession:
    case ValidLibraryObjectActionState.pending: {
      return (
        <BreakoutButton
          kind={isHeader ? 'primary' : 'secondary'}
          size={!isHeader ? 'small' : 'medium'}
          onClick={(e) => {
            navigateWithAction(ValidLibraryObjectActionState.scheduleSession, e)
          }}
        >
          {t('library.actions.schedule_session')}
        </BreakoutButton>
      )
    }
    case ValidLibraryObjectActionState.completeQuiz: {
      return (
        <BreakoutButton
          kind={isHeader ? 'primary' : 'secondary'}
          size={!isHeader ? 'small' : 'medium'}
          onClick={(e) => {
            navigateWithAction(actionState, e)
          }}
        >
          {t('library.actions.complete_quiz')}
        </BreakoutButton>
      )
    }
    case ValidLibraryObjectActionState.sessionScheduled: {
      return (
        <div className="flex flex-col items-center justify-center gap-1">
          <ClockIcon size={15} />
          <span className="text-body-medium text-on-surface">
            {t('library.actions.session_scheduled')}
          </span>
        </div>
      )
    }
    case ValidLibraryObjectActionState.joinSession: {
      return (
        <BreakoutButton
          kind="primary"
          size={!isHeader ? 'small' : 'medium'}
          onClick={(e) => {
            navigateWithAction(actionState, e)
          }}
        >
          {t('library.actions.join_session')}
        </BreakoutButton>
      )
    }
    case ValidLibraryObjectActionState.startSession: {
      return (
        <BreakoutButton
          size={!isHeader ? 'small' : 'medium'}
          kind="primary"
          onClick={(e) => {
            navigateWithAction(actionState, e)
          }}
        >
          {t('library.actions.start_session')}
        </BreakoutButton>
      )
    }
    case ValidLibraryObjectActionState.viewResults: {
      return (
        <BreakoutButton
          icon={<ChevronRight size={15} />}
          iconOnRight={true}
          size={!isHeader ? 'small' : 'medium'}
          kind={isHeader ? 'primary' : 'tertiary'}
          onClick={(e) => {
            navigateWithAction(actionState, e)
          }}
        >
          {t('library.actions.view_results')}
        </BreakoutButton>
      )
    }
    case ValidLibraryObjectActionState.experienceExpired: {
      return (
        <div className="flex flex-col items-center justify-center gap-1">
          <CircleX size={15} className="stroke-core-error" />
          <span className="text-body-medium text-core-error">
            {t('library.actions.experience_expired')}
          </span>
        </div>
      )
    }
  }
})

export const PriorityActionHeader = observer(function PriorityActionHeader({
  libraryObject,
  actionState,
  cubit,
}: {
  libraryObject: ValidLibraryObject
  actionState: ValidLibraryObjectActionState
  cubit: StudentLibraryCubit
}) {
  const { t } = useTranslation()
  return (
    <div className="mb-5 flex w-full flex-row items-center gap-5 rounded-[20px] bg-surface px-7 py-4">
      <div className="flex flex-col justify-center">
        <span className="text-body-medium text-on-surface-var">
          {t('library.next_action')}
        </span>
        <span className="text-title-large">
          {t(`library.actions.header.${actionState}`)}
        </span>
      </div>
      <div className="w-1 min-w-1 self-stretch bg-fixed-accent-color"></div>
      <div className="text-body-medium flex min-w-[150px] flex-col">
        <span>{libraryObject.section.data.className}</span>
        <span className="text-on-surface-var">
          {libraryObject.instructor.fullName}
        </span>
      </div>
      <div className="flex flex-grow flex-row gap-1">
        {libraryObject.slideDeckImageURL && (
          <div className="aspect-square h-10 w-10 p-2">
            <img
              src={libraryObject.slideDeckImageURL}
              className="h-full w-full object-cover"
            />
          </div>
        )}
        <div className="flex flex-col justify-center">
          <span className="text-body-medium line-clamp-1 text-on-surface-var">
            {libraryObject.slideDeck.data.slideDeckName}
          </span>
          {libraryObject.slideDeck.data.slideDeckTeaser && (
            <span className="text-label-large line-clamp-1">
              {libraryObject.slideDeck.data.slideDeckTeaser}
            </span>
          )}
        </div>
      </div>
      <div className="flex h-full items-center justify-center">
        <ActionForLibraryObject
          cubit={cubit}
          libraryObject={libraryObject}
          isHeader={true}
        />
      </div>
    </div>
  )
})
