import type { InstructorSectionCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorSectionCubit'
import { InvitationType } from '@breakoutlearning/firebase-repository/types'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { LinkIcon } from 'components/icons/Link'
import { useDialogs } from 'hooks/dialogs'
import { t } from 'i18next'
import { useState } from 'react'
import { copyAsyncTextToClipboard } from 'util/clipboard'

export function SectionInvitationDialog({
  cubit,
}: {
  cubit: InstructorSectionCubit
}) {
  const [loadingLinkType, setLoadingLinkType] = useState<InvitationType>()
  const { showDialog } = useDialogs()

  const handleGenerateLink = async (type: InvitationType) => {
    setLoadingLinkType(type)

    const { success, output: invitationUrl } = await copyAsyncTextToClipboard(
      async () => {
        const id = await cubit.createStudentInvitation({ type })
        const invitationUrl = `${window.location.origin}/invitation/${id}`
        return invitationUrl
      }
    )

    if (success) {
      setLoadingLinkType(undefined)
      showDialog(() => (
        <DisplayInvitationDialog invitationUrl={invitationUrl} />
      ))
    } else {
      showDialog(() => (
        <DisplayInvitationDialog invitationUrl={invitationUrl} copyFailed />
      ))
    }
  }

  // Disable buttons if we are loading a link.
  const disableButtons = typeof loadingLinkType !== 'undefined'

  return (
    <Dialog
      key="dialog-select-url"
      size="xs"
      className="overflow-auto bg-core-tertiary outline-none"
    >
      <DialogCloseButton />

      <h2 className="text-2xl font-semibold">
        {t('instructor_class.create_invite_link')}
      </h2>
      <p className="mt-4 whitespace-pre-wrap text-sm">
        {t('instructor_class.invite_students_instructions')}
      </p>
      <BreakoutButton
        disabled={disableButtons}
        size="large"
        onClick={() => handleGenerateLink(InvitationType.multiUse)}
        className="mt-6 w-full"
      >
        {t('instructor_class.three_month_link')}
      </BreakoutButton>
      <BreakoutButton
        disabled={typeof loadingLinkType !== 'undefined'}
        onClick={() => handleGenerateLink(InvitationType.oneTime)}
        size="large"
        kind="secondary"
        className="mt-2 w-full"
      >
        {t('instructor_class.one_time_link')}{' '}
      </BreakoutButton>
    </Dialog>
  )
}

function DisplayInvitationDialog({
  copyFailed,
  invitationUrl,
}: {
  copyFailed?: boolean
  invitationUrl: string
}) {
  const { clearAllDialogs } = useDialogs()
  return (
    <Dialog
      size="xs"
      key="dialog-show-url"
      innerClassName="flex flex-col justify-center"
    >
      <DialogCloseButton />

      <h3 className="text-center text-xl font-semibold">
        {copyFailed
          ? t('instructor_class.linked_copied_clipboard_failure')
          : t('instructor_class.link_copied_clipboard')}
      </h3>
      <p className="mt-4 text-center text-xs">
        {t('instructor_class.link_warning')}
      </p>
      <BreakoutTextInput
        data-testid="invitation-url"
        className="mt-6"
        inputClassName="cursor-text w-full"
        iconClassName="cursor-pointer"
        kind="secondary"
        onChange={() => {}}
        LeadingIcon={({ ...props }) => (
          <LinkIcon
            onClick={() => {
              navigator.clipboard.writeText(invitationUrl)
              clearAllDialogs()
            }}
            {...props}
          />
        )}
        value={invitationUrl}
      />
    </Dialog>
  )
}
