import type { InstructorClassesCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorClassesCubit'
import { Spinner } from 'components/Spinner'
import { observer } from 'mobx-react-lite'
import { SectionListItem } from './SectionListItem'
import { type Section } from '@breakoutlearning/firebase-repository/models/Section'
import {
  BreakoutButton,
  BreakoutIconButton,
} from 'components/design-system/BreakoutButton'
import { useTranslation } from 'react-i18next'
import { useDialogs } from 'hooks/dialogs'
import { AddSectionDialog } from './AddSectionDialog'
import { PlusIcon } from 'components/icons/Plus'
import { Close } from 'components/icons/Close'
import { Dialog } from 'components/dialogs/Dialog'
import { useCallback, useState } from 'react'
import { type PublicUser } from '@breakoutlearning/firebase-repository/models/PublicUser'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { BreakoutCard } from 'components/design-system/BreakoutCard'
import { UserProfileRole } from '@breakoutlearning/firebase-repository/types'

export const SectionList = observer(function SectionList({
  cubit,
}: {
  cubit: InstructorClassesCubit
}) {
  if (cubit.sectionDataLoading) {
    return (
      <div className="flex h-full items-center justify-center">
        <Spinner />
      </div>
    )
  }
  if (cubit.role !== UserProfileRole.ta)
    return <SectionCards sections={cubit.visibleSections} cubit={cubit} />

  return (
    <div className="mt-7 flex flex-col gap-5">
      {Object.entries(cubit.sectionsByInstructor).map(
        ([, { sections, instructor }], i) => (
          // use index for key if instructor is empty
          <div key={instructor.id || i} className="flex flex-col gap-5">
            <div className="flex w-full flex-row items-center justify-between rounded-2xl bg-surface p-2">
              <h2 className="text-headline-large">
                {instructor.data.firstName} {instructor.data.lastName}
              </h2>
              <div className="flex flex-row gap-2.5">
                <TASectionButtons cubit={cubit} instructor={instructor} />
              </div>
            </div>
            <SectionCards sections={sections} cubit={cubit} />
          </div>
        )
      )}
    </div>
  )
})

const SectionCards = ({
  cubit,
  sections,
}: {
  cubit: InstructorClassesCubit
  sections: Section[]
}) => (
  // <div className="flex flex-row flex-wrap gap-5">
  <BreakoutCard.Grid>
    {sections.map((section) => (
      <SectionListItem key={section.id} cubit={cubit} section={section} />
    ))}
    {/* </div> */}
  </BreakoutCard.Grid>
)

const TASectionButtons = observer(function TASectionButtons({
  cubit,
  instructor,
}: {
  cubit: InstructorClassesCubit
  instructor: PublicUser
}) {
  const { t } = useTranslation()
  const { showDialog, popDialog } = useDialogs()

  return (
    <>
      <BreakoutButton
        size="large"
        icon={<PlusIcon size={14} />}
        onClick={() => {
          showDialog(() => (
            <AddSectionDialog
              createSection={cubit.createSection}
              onCreate={popDialog}
              instructorUserId={instructor.id}
              redeemPromotions={cubit.redeemPromotions}
            />
          ))
        }}
      >
        {t('instructor_library.add_class')}
      </BreakoutButton>
      <BreakoutIconButton
        kind="error"
        icon={<Close size={24} />}
        onClick={() => {
          showDialog(() => (
            <RetireAsTADialog
              onConfirm={() => cubit.retireAsTA(instructor.id)}
            />
          ))
        }}
      />
    </>
  )
})

const RetireAsTADialog = observer(function RetireAsTADialog({
  onConfirm,
}: {
  onConfirm: () => Promise<void>
}) {
  const { t } = useTranslation()
  const tScoped = useCallback(
    (key: string) => t(`instructor_library.${key}`),
    [t]
  )
  const [loading, setLoading] = useState(false)
  const { popDialog } = useDialogs()
  const onConfirmClicked = useCallback(async () => {
    setLoading(true)
    try {
      await onConfirm()
    } finally {
      setLoading(false)
      popDialog()
    }
  }, [onConfirm, setLoading, popDialog])
  return (
    <Dialog size="sm" innerClassName="flex flex-col justify-between">
      <DialogCloseButton className="absolute right-0 top-0 p-5 pb-0" />
      <div className="flex flex-col gap-6">
        <h2 className="text-headline-large">{tScoped('retire_as_ta')}</h2>
        <span className="text-body-medium">
          {tScoped('retire_as_ta_confirmation')}
        </span>
      </div>
      <div className="flex flex-row gap-3">
        <BreakoutButton
          disabled={loading}
          size="large"
          kind="secondary"
          className="flex-grow"
          onClick={() => popDialog()}
        >
          {tScoped('no')}
        </BreakoutButton>
        <BreakoutButton
          loading={loading}
          size="large"
          kind="primary"
          className="flex-grow"
          onClick={onConfirmClicked}
        >
          {tScoped('yes')}
        </BreakoutButton>
      </div>
    </Dialog>
  )
})
