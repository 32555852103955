import type { InstructorClassesCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorClassesCubit'
import type { Section } from '@breakoutlearning/firebase-repository/models/Section'
import { InstructorSectionCard } from 'components/design-system/cards/InstructorSectionCard'
import { useRootStore } from 'hooks/rootStore'
import { observer } from 'mobx-react-lite'

export const SectionListItem = observer(function SectionListItem({
  cubit,
  section,
}: {
  cubit: InstructorClassesCubit
  section: Section
}) {
  const store = useRootStore()
  const assignments = cubit.assignmentsForSection.get(section.id)
  const promotions = cubit.promotionsForSection.get(section.id) || []
  const promotionNames = promotions.map((p) => p.data.promotionName)
  return (
    <InstructorSectionCard
      sectionName={section.data.sectionName}
      className={section.data.className}
      onClick={() => store.navigateTo('instructorClass', { id: section.id })}
      userCount={section.userCount}
      caseCount={assignments ? assignments.length : undefined}
      state={section.data.sectionState}
      testId={`section-list-item-${section.id}`}
      isSharedSection={cubit.isSharedSection(section)}
      promotionNames={promotionNames}
    />
  )
})
