import type { UserPromotion } from '@breakoutlearning/firebase-repository/models/UserPromotion'
import type { UserPromotionRedemption } from '@breakoutlearning/firebase-repository/models/UserPromotionRedemption'
import classNames from 'classnames'
import type { InstructorClassesCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorClassesCubit'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { BreakoutSelect } from 'components/design-system/BreakoutSelect'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { FormLabel } from 'components/design-system/form/FormLabel'
import { Dialog } from 'components/dialogs/Dialog'
import { TrashCanIcon } from 'components/icons/TrashCan'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useBreakoutUser } from 'hooks/profile'
import { BreakoutTooltip } from 'components/design-system/BreakoutTooltip'

export const AddSectionDialog = observer(function AddSectionDialog({
  createSection,
  onCreate,
  instructorUserId,
  redeemPromotions,
  title,
}: {
  instructorUserId?: string
  onCreate?: (id?: string) => void
  createSection: InstructorClassesCubit['createSection']
  redeemPromotions: InstructorClassesCubit['redeemPromotions']
  title?: string
}) {
  const user = useBreakoutUser()
  const userPromotions = user.userPromotions.models
  const userPromotionRedemptions = user.userPromotionRedemptions
  const { t } = useTranslation()
  const [isSaving, setIsSaving] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [selectedUserPromotion, setSelectedUserPromotion] = useState<
    string | null
  >(null)
  const [selectedUserPromotionIds, setSelectedUserPromotionIds] = useState<
    string[]
  >([])

  const promotionIsActive = useMemo(() => {
    if (!selectedUserPromotion) return false
    const userPromotion = userPromotions?.find(
      (p) => p.id === selectedUserPromotion
    )
    return !!userPromotion?.isActive
  }, [selectedUserPromotion, userPromotions])

  const promotionIsConsumable = useMemo(() => {
    if (!selectedUserPromotion) return false
    const userPromotion = userPromotions?.find(
      (p) => p.id === selectedUserPromotion
    )
    const redemptions =
      userPromotionRedemptions?.get(selectedUserPromotion) || []
    if (!userPromotion) return false
    if (!userPromotion.data.consumableCount) return false
    const consumedCount = redemptions.length
    return consumedCount < userPromotion.data.consumableCount
  }, [selectedUserPromotion, userPromotions, userPromotionRedemptions])

  const userPromotionOptions = useMemo(() => {
    const unusedPromotions = userPromotions?.filter(
      (p) => !selectedUserPromotionIds.includes(p.id)
    )
    return (
      unusedPromotions?.map((userPromotion) => ({
        label: userPromotion.data.promotionName,
        value: userPromotion.id,
      })) || []
    )
  }, [userPromotions, selectedUserPromotionIds])

  const onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      const formData = new FormData(e.currentTarget)
      const className = (formData.get('className') as string).trim()
      const sectionName = (formData.get('sectionName') as string).trim()

      if (!className || !sectionName) {
        setError('Please fill out all fields')
        return
      }

      setIsSaving(true)

      const doc = await createSection(className, sectionName, instructorUserId)

      const selectedUserPromotions =
        userPromotions?.filter((p) =>
          selectedUserPromotionIds.includes(p.id)
        ) || []

      if (selectedUserPromotions.length > 0) {
        try {
          await redeemPromotions(doc.id, selectedUserPromotions)
        } catch (e) {
          console.error('Error redeeming promotions', e)
        }
      }

      setIsSaving(false)
      onCreate?.(doc.id)
    },
    [
      createSection,
      onCreate,
      redeemPromotions,
      instructorUserId,
      userPromotions,
      selectedUserPromotionIds,
    ]
  )

  const hasPromotions = !!userPromotions && userPromotions.length > 0

  let tooltip: string | undefined = undefined
  if (selectedUserPromotion && !promotionIsActive) {
    tooltip = t('instructor_library.promotion_not_active')
  } else if (selectedUserPromotion && !promotionIsConsumable) {
    tooltip = t('instructor_library.promotion_not_consumable')
  }

  return (
    <Dialog
      testId="add-section-dialog"
      size="md"
      innerClassName={classNames('flex flex-col justify-between ', {
        'md:!w-[800px]': hasPromotions,
      })}
    >
      <div className="text-headline-large mb-4 text-core-on-tertiary">
        {title || t('instructor_library.add_class')}
      </div>
      <form
        className="flex flex-1 flex-col justify-between gap-5"
        onSubmit={onSubmit}
      >
        <div>
          <div className="flex flex-row gap-3">
            <div className="flex flex-1 flex-col gap-2">
              <BreakoutTextInput
                label={t('instructor_library.class_name')}
                name="className"
                autoFocus
                data-testid="class-name-input"
                kind="secondary"
              />
              <BreakoutTextInput
                label={t('instructor_library.section_name')}
                name="sectionName"
                data-testid="section-name-input"
                kind="secondary"
              />
            </div>
            {hasPromotions && (
              <div className="flex-1">
                <div className="flex flex-col items-start gap-1">
                  <FormLabel label={t('instructor_library.apply_promotions')} />
                  <div className="flex w-full flex-row items-center justify-between gap-2">
                    <BreakoutSelect
                      inputClassName="max-w-[180px] min-w-[180px]"
                      fullWidth
                      kind="secondary"
                      value={selectedUserPromotion}
                      onChange={(value) => {
                        setSelectedUserPromotion(value)
                      }}
                      className="!w-full"
                      options={userPromotionOptions}
                    />
                    <BreakoutButton
                      size="large"
                      disabled={
                        !selectedUserPromotion ||
                        !promotionIsConsumable ||
                        !promotionIsActive
                      }
                      kind="secondary"
                      className="!max-w-[100px]"
                      tooltip={tooltip}
                      onClick={() => {
                        if (!promotionIsConsumable) return
                        if (selectedUserPromotion) {
                          setSelectedUserPromotionIds([
                            ...selectedUserPromotionIds,
                            selectedUserPromotion,
                          ])
                          setSelectedUserPromotion(null)
                        }
                      }}
                    >
                      {t('instructor_library.apply')}
                    </BreakoutButton>
                  </div>
                  <div className="flex max-h-[350px] w-full flex-col gap-2 overflow-auto">
                    {selectedUserPromotionIds.map((id) => {
                      const userPromotion = userPromotions?.find(
                        (p) => p.id === id
                      )
                      const redemptions =
                        userPromotionRedemptions?.get(id) || []
                      if (!userPromotion) return null
                      return (
                        <UserPromotionRow
                          key={id}
                          userPromotion={userPromotion}
                          redemptions={redemptions}
                          onRemove={() => {
                            setSelectedUserPromotionIds(
                              selectedUserPromotionIds.filter((i) => i !== id)
                            )
                          }}
                        />
                      )
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
          {error && <div className="text-breakout-red">{error}</div>}
        </div>
        <div>
          <BreakoutButton
            size="large"
            type="submit"
            fullWidth
            loading={isSaving}
            data-testid="add-class-button"
          >
            {t('instructor_library.add_class')}
          </BreakoutButton>
        </div>
      </form>
    </Dialog>
  )
})

const UserPromotionRow = observer(function UserPromotionRow({
  userPromotion,
  redemptions,
  onRemove,
}: {
  userPromotion: UserPromotion
  redemptions: UserPromotionRedemption[]
  onRemove?: () => void
}) {
  const { t } = useTranslation()
  const data = userPromotion.data
  const isActive = userPromotion.isActive
  const consumed = redemptions.length
  const expirationDateTime = DateTime.fromJSDate(data.expirationDate)
  return (
    <div className="flex flex-row  items-center justify-between rounded-2xl bg-surface p-5">
      <div>
        <div
          className={classNames('text-label-small uppercase', {
            'text-core-error': !isActive,
            'text-core-success': isActive,
          })}
        >
          {isActive ? t('profile.active') : t('profile.inactive')}
        </div>
        <div className="text-label-medium">{data.promotionName}</div>
        <div className="text-body-small text-on-surface-var">
          {t('profile.consumed_x_of_y', {
            consumed,
            total: data.consumableCount,
          })}
        </div>
        <div className="text-body-small text-on-surface-var">
          {t('profile.expires_at', {
            expirationDate: expirationDateTime.toLocaleString(
              DateTime.DATE_FULL
            ),
          })}
        </div>
      </div>
      <BreakoutTooltip
        content={t('instructor_library.delete_promotion_tooltip')}
        className="max-w-[330px]"
      >
        <div className="text-center">
          <TrashCanIcon
            onClick={onRemove}
            size={15}
            className="cursor-pointer"
          />
        </div>
      </BreakoutTooltip>
    </div>
  )
})
