import classNames from 'classnames'
import type { ReactElement } from 'react'
import { cloneElement, useRef, useState } from 'react'
import type { Placement, UseHoverProps } from '@floating-ui/react'
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useInteractions,
  FloatingPortal,
  useTransitionStyles,
  arrow,
  FloatingArrow,
} from '@floating-ui/react'

const theme = {
  tooltip: {
    base: {
      className:
        'bg-on-surface-var text-xs text-core-tertiary p-2 rounded-lg cursor-default relative z-[9999] min-w-[30px] text-center',
    },
    transition: {
      duration: 300,
    },
  },
}

// eslint-disable-next-line react-refresh/only-export-components
export function hideAllTooltips() {
  const event = new KeyboardEvent('keydown', {
    key: 'Escape',
  })
  document.dispatchEvent(event)
}

export function BreakoutTooltip({
  content,
  children,
  placement,
  delay,
  portal = true,
  portalId,
  className,
  transitionDuration,
  enabled = true,
  ariaHidden = false,
}: {
  enabled?: boolean
  className?: string
  content: ReactElement | string
  children: ReactElement | string
  placement?: Placement
  delay?: UseHoverProps['delay']
  // Whether to render the tooltip in a portal or not - defaults to true
  portal?: boolean
  portalId?: string
  ariaHidden?: boolean
  transitionDuration?: number
}) {
  const [isOpen, setIsOpen] = useState(false)
  const tooltipPlacement = placement || 'bottom'

  const arrowRef = useRef(null)

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: tooltipPlacement,
    // Make sure the tooltip stays on the screen
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(10),
      flip({
        fallbackAxisSideDirection: 'start',
      }),
      shift(),
      arrow({
        element: arrowRef,
      }),
    ],
  })

  const { isMounted, styles } = useTransitionStyles(context, {
    // Configure both open and close durations:
    duration: transitionDuration ?? theme.tooltip.transition.duration,
  })

  // Event listeners to change the open state
  const hover = useHover(context, { move: false, delay })
  const focus = useFocus(context)
  const dismiss = useDismiss(context)

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
  ])

  const tooltipClassName = theme.tooltip.base.className

  const contentIsReactElement = typeof content !== 'string'

  const component = isMounted && (
    <div
      aria-hidden={ariaHidden}
      className={classNames(className, {
        [tooltipClassName]: !contentIsReactElement,
        'relative z-[9999] cursor-default': contentIsReactElement,
      })}
      ref={refs.setFloating}
      style={{ ...floatingStyles, ...styles }}
      {...getFloatingProps()}
    >
      {!contentIsReactElement && (
        <FloatingArrow
          className="fill-on-surface-var"
          ref={arrowRef}
          context={context}
        />
      )}
      {content}
    </div>
  )

  if (!enabled) {
    return children
  }

  return (
    <>
      {typeof children === 'string' ? (
        <span
          aria-hidden={ariaHidden}
          ref={refs.setReference}
          {...getReferenceProps()}
        >
          {children}
        </span>
      ) : (
        cloneElement(children, {
          ref: refs.setReference,
          ...getReferenceProps({}),
        })
      )}
      {portal ? (
        <FloatingPortal id={portalId}>{component}</FloatingPortal>
      ) : (
        component
      )}
    </>
  )
}
