import type { StudentAssignmentCubit } from '@breakoutlearning/firebase-repository/cubits/StudentAssignmentCubit'
import { Button } from 'components/Button'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { Money } from 'components/icons/Money'
import { useDialogs } from 'hooks/dialogs'
import { useBreakoutUser } from 'hooks/profile'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-hot-toast'
import { Spinner } from 'components/Spinner'

export const PurchaseDialogBody = observer(function PurchaseDialogBody({
  cubit,
}: {
  cubit: StudentAssignmentCubit
}) {
  const { availableTokens } = useBreakoutUser()

  const tokensRequired = cubit.slideDeck.data.slideDeckPrice
  const totalPrice = (
    Math.max((tokensRequired - availableTokens) * 10, 0) / 100
  ).toFixed(2)
  const { popDialog } = useDialogs()
  const { t } = useTranslation()
  const [waitingForStripeURL, setWaitingForStripeURL] = useState(false)

  if (waitingForStripeURL) {
    return (
      <div className="flex flex-grow items-center justify-center">
        <Spinner />
      </div>
    )
  }

  return (
    <>
      <DialogCloseButton />
      <h1 className="text-headline-large mb-4px text-center">
        {t('student_assignment.purchase_tokens')}
      </h1>
      <p className="text-body-medium mb-1 text-center">
        {t('student_assignment.purchase_tokens_description')}
      </p>
      <div className="text-body-medium mb-1 w-[8rem]">
        <div className="flex flex-row justify-between">
          <span>{t('student_assignment.tokens_required_colon')}</span>
          <span className="ml-auto">{tokensRequired}</span>
        </div>
      </div>
      <div className="text-body-medium mb-3 w-[8rem]">
        <div className="flex flex-row justify-between">
          <span>{t('student_assignment.tokens_available_colon')}</span>
          <span className="text-right">{availableTokens}</span>
        </div>
      </div>
      <div className="bg-lightGray mb-5 flex h-[200px] w-[200px] flex-col items-center justify-center rounded-[20px] p-[20px] shadow-2xl">
        <span className="text-body-medium mb-8px mx-auto table">
          {t('student_assignment.tokens_to_purchase')}
        </span>
        <span className="text-display-medium mb-8px mx-auto table">
          {Math.max(tokensRequired - availableTokens, 0)}
        </span>
        <span className="text-headline-medium mb-8px mx-auto table">
          {t('student_assignment.tokens')}
        </span>
        <span className="text-label-medium mb-8px mx-auto table">
          {t('student_assignment.total_price_colon')} ${totalPrice}
        </span>
      </div>
      <PurchaseButton
        onPurchase={async () => {
          setWaitingForStripeURL(true)
          try {
            await cubit.createPayment(tokensRequired - availableTokens)
          } catch (e) {
            toast.error(t('student_assignment.pay_with_stripe_failure'))
            // rethrow to bubble to global error handler (sentry)
            popDialog()
            throw e
          }
        }}
      />
    </>
  )
})

const PurchaseButton = function ({ onPurchase }: { onPurchase: () => void }) {
  const { t } = useTranslation()
  return (
    <Button
      defaultWidth={false}
      onClick={onPurchase}
      overrideClassName="text-label-large bg-core-primary text-core-on-primary h-[54px] w-[200px] rounded-2xl font-semibold"
    >
      <Money
        size={14}
        className="mr-1 inline-block font-bold text-core-on-primary"
      />
      {t('student_assignment.pay_with_stripe')}
    </Button>
  )
}
