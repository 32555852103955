import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { PreWorkModal } from './PreWorkModal'
import { QuizModal } from './QuizModal'
import { LibraryObjectState } from '@breakoutlearning/firebase-repository/types'
import { useDialogs } from 'hooks/dialogs'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { ChevronRight } from 'components/icons/ChevronRight'
import { QuizResultsDialog } from './dialogs/QuizResultsDialog'
import { Dialog } from 'components/dialogs/Dialog'
import { useStudentAssignmentCubit } from 'hooks/cubits/studentAssignment'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { BreakoutButton } from 'components/design-system/BreakoutButton'

const PreWorkDoneView = observer(function PreWorkDoneView() {
  const cubit = useStudentAssignmentCubit()
  const { t } = useTranslation()
  const { showDialog } = useDialogs()
  return (
    <div className={classNames(`mt-1 flex-grow-0 py-5 text-center`)}>
      <div className="text-body-medium text-grey-text">
        {t('student_assignment.quiz_results')}
      </div>
      <h3 className="text-display-medium mt-3">{cubit.preWorkQuizResult}%</h3>
      <button
        className="text-label-medium mt-2 flex outline-offset-2"
        onClick={() => {
          showDialog(({ remove }) => (
            <QuizResultsDialog cubit={cubit} removeSelf={remove} />
          ))
        }}
      >
        {t('student_assignment.view_details')}
        <ChevronRight size={16} />
      </button>
    </div>
  )
})

export const StudentAssignmentStep2 = observer(
  function StudentAssignmentStep2() {
    const cubit = useStudentAssignmentCubit()
    const { showDialog } = useDialogs()
    const libraryObject = cubit.libraryObject
    const ready = cubit.isReady
    const { t } = useTranslation()

    const openQuizDialog = useCallback(() => {
      showDialog(({ remove: removeStartQuizModal }) => {
        return (
          <StartQuizModal
            onClick={() => {
              showDialog(({ remove: removeQuizModal }) => {
                return (
                  <QuizModal
                    cubit={cubit}
                    closeModal={() => {
                      removeQuizModal()
                    }}
                  />
                )
              })
              removeStartQuizModal()
            }}
          />
        )
      })
    }, [cubit, showDialog])

    if (!ready) {
      return (
        <div
          className={classNames(`mt-1 flex-grow-0 py-5 text-center opacity-50`)}
        >
          <div className=" text-body-medium text-grey-text">
            {t('student_assignment.step_2')}
          </div>
          <h3 className="text-title-large mt-3">
            {t('student_assignment.complete_pre_work_title')}
          </h3>
          <div className="h-[80px]"></div>
        </div>
      )
    }

    const preWorkNotComplete =
      cubit.hasQuizData &&
      cubit.hasPreWorkQuiz &&
      !cubit.allPreWorkQuestionsAnswered

    const preWorkDone =
      cubit.hasQuizData &&
      cubit.hasPreWorkQuiz &&
      cubit.allPreWorkQuestionsAnswered

    const isCompleted =
      libraryObject.libraryObjectState === LibraryObjectState.completed

    if (isCompleted || preWorkDone) {
      return <PreWorkDoneView />
    }

    return (
      <div
        className={classNames(
          `mt-1 w-full flex-grow-0 py-5 text-center`,
          !cubit.roomState.isScheduled ? 'min-h-[100px] opacity-30' : 'h-full'
        )}
      >
        <div className="text-body-medium text-grey-text">
          {t('student_assignment.step_2')}
        </div>
        <h3 className="text-title-large mt-3">
          {cubit.roomState.isScheduled
            ? t('student_assignment.complete_pre_work_title')
            : t('student_assignment.complete_pre_work')}
        </h3>

        {cubit.roomState.isScheduled && (
          <div className="overflow-hidden">
            {preWorkNotComplete && (
              <p className="text-body-medium mt-1">
                {t('student_assignment.complete_pre_work_body')}
              </p>
            )}
            {preWorkDone && (
              <>
                <p className="text-body-medium mt-5">{t('dart.completed')}</p>

                <p className="text-body-medium mt-5">
                  {cubit.preWorkQuizResult}
                </p>
              </>
            )}
            <div
              className={classNames('mt-3', preWorkNotComplete ? '' : 'hidden')}
            >
              <BreakoutButton
                data-testid="show-pre-work-button"
                onClick={() => {
                  showDialog(() => <PreWorkModal cubit={cubit} />)
                }}
                disabled={!cubit.roomState.isScheduled}
                className="text-label-large mb-2 mr-2 inline-block w-40 rounded-2xl bg-core-secondary px-6 py-2 text-core-on-secondary outline-offset-2"
              >
                {t('student_assignment.show_prework')}
              </BreakoutButton>
              {cubit.hasQuizData && cubit.hasPreWorkQuiz && (
                <BreakoutButton
                  data-testid="start-quiz-button"
                  onClick={openQuizDialog}
                  disabled={!cubit.roomState.isScheduled}
                  className="text-label-large inline-block w-40 rounded-2xl bg-core-primary px-8 py-2 text-core-on-primary outline-offset-2"
                >
                  {t('student_assignment.start_quiz')}
                </BreakoutButton>
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
)

export function StartQuizModal({ onClick }: { onClick: () => void }) {
  const { t } = useTranslation()
  return (
    <Dialog testId="start-quiz-modal" size="sm">
      <DialogCloseButton />
      <div className="flex flex-col items-center justify-center text-center">
        <h1 className="text-headline-large mb-3 text-center">
          {t('student_assignment.start_quiz')}
        </h1>
        <p className="text-body-medium">
          {t('student_assignment.start_quiz_warning')}
        </p>
        <p className="text-label-medium mt-2">
          {t('student_assignment.quiz_duration')}
        </p>

        <BreakoutButton
          data-testid="confirm-quiz-button"
          onClick={onClick}
          size="large"
          className="mt-10 w-full"
        >
          {t('student_assignment.confirm')}
        </BreakoutButton>
      </div>
    </Dialog>
  )
}
