import { useRootStore } from '../../hooks/rootStore'
import { observer } from 'mobx-react-lite'
import { getRouteName, routes } from 'config/routes'
import type { MouseEvent } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { reaction } from 'mobx'
import { BreakoutTooltip } from '../../components/design-system/BreakoutTooltip'

export const NavLink = observer(function NavLink({
  to,
  tooltip,
  className,
  activeClassName,
  activeOn,
  children,
  onClick,
}: {
  tooltip?: string
  to: string
  className?: string
  activeClassName?: string
  activeOn?: string
  children: React.ReactNode
  onClick?: () => void
}) {
  const store = useRootStore()
  const [fullClassName, setFullClassName] = useState(className)
  const route = routes[to]
  const path = route?.path

  const activeOnArray = useMemo(() => {
    if (activeOn) {
      return activeOn.split(',').map((s) => s.trim())
    }
    return [to]
  }, [activeOn, to])

  const onClickHandler = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault()
      onClick && onClick()
      store.navigateTo(to)
    },
    [store, to, onClick]
  )

  useEffect(() => {
    return reaction(
      () => store.router.currentRoute,
      (route) => {
        const pathName = getRouteName(route)
        let fullClassName = className
        if (pathName && activeOnArray.includes(pathName)) {
          fullClassName = `${className} ${activeClassName}`
        }
        setFullClassName((prev) => {
          if (prev === fullClassName) {
            return prev
          }
          return fullClassName
        })
      },
      { fireImmediately: true }
    )
  }, [store, activeClassName, className, to, activeOnArray])

  const anchor = (
    <a
      data-testid={to}
      role="link"
      aria-label={tooltip}
      href={path}
      onClick={onClickHandler}
      className={fullClassName}
    >
      {children}
    </a>
  )

  if (!tooltip) {
    return anchor
  }

  return (
    <BreakoutTooltip ariaHidden content={tooltip}>
      {anchor}
    </BreakoutTooltip>
  )
})
