import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { useBreakoutUser } from 'hooks/profile'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'

export const ProfileInfoSection = observer(function ProfileInfoSection() {
  const { t } = useTranslation()
  const breakoutUser = useBreakoutUser()

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName: breakoutUser.firstName,
      lastName: breakoutUser.lastName,
      email: breakoutUser.emailAddress,
    },
  })

  const onSubmit = async ({
    firstName,
    lastName,
  }: {
    firstName: string
    lastName: string
  }) => {
    await breakoutUser.updateName(firstName, lastName)
    toast.success(t('profile.profile_updated'))
    return
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex h-full flex-col justify-between"
    >
      <div>
        <h2 className="text-headline-large text-core-on-tertiary">
          {t('profile.details_headline')}
        </h2>

        <div className="mt-6 flex flex-col gap-3">
          <Controller
            control={control}
            name="firstName"
            rules={{
              required: t('profile.first_name_required'),
            }}
            render={({ field, fieldState }) => (
              <BreakoutTextInput
                kind="secondary"
                label={t('profile.first_name')}
                data-testid="firstNameInput"
                error={fieldState.error}
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="lastName"
            rules={{
              required: t('profile.last_name_required'),
            }}
            render={({ field, fieldState }) => (
              <BreakoutTextInput
                kind="secondary"
                label={t('profile.last_name')}
                data-testid="lastNameInput"
                error={fieldState.error}
                {...field}
              />
            )}
          />

          <Controller
            control={control}
            name="email"
            rules={{
              required: t('profile.email_required'),
            }}
            render={({ field, fieldState }) => (
              <BreakoutTextInput
                disabled
                kind="secondary"
                label={t('profile.email')}
                data-testid="emailInput"
                error={fieldState.error}
                {...field}
              />
            )}
          />
        </div>
      </div>
      <div className="flex justify-end">
        <BreakoutButton
          data-testid="save-profile"
          type="submit"
          size="large"
          kind="primary"
          className="mt-3"
          disabled={isSubmitting}
          loading={isSubmitting}
        >
          {t('profile.save')}
        </BreakoutButton>
      </div>
    </form>
  )
})
