import { InvitationConsumeResult } from '@breakoutlearning/firebase-repository/types'
import { Spinner } from 'components/Spinner'
import { useRepository } from 'hooks/auth'
import { useDialogs } from 'hooks/dialogs'
import { useBreakoutUser } from 'hooks/profile'
import { useRootStore } from 'hooks/rootStore'
import { useEffect } from 'react'
import { toast } from 'react-hot-toast'
import { InvitationAcceptedDialog } from './InvitationAcceptedDialog'
import { InstructorSectionCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorSectionCubit'
import { useTranslation } from 'react-i18next'
import { useCubitBuilder } from 'hooks/cubits'
import { InvitationTokenCubit } from '@breakoutlearning/firebase-repository/cubits/InvitationTokenCubit'

export function InvitationTokenPage() {
  const repository = useRepository()
  const rootStore = useRootStore()
  const router = rootStore.router
  const user = useBreakoutUser()
  const { showDialog } = useDialogs()
  const { t } = useTranslation()

  /**
   * as of now all this does is act as a proxy to call the isSectionInvitationFunction
   * but we need it to maintain our pattern of not calling firestore functions directly
   * from view. tldr: we need cubit as the C in MVC
   */
  const cubit = useCubitBuilder(
    () => new InvitationTokenCubit(repository),
    [repository]
  )

  useEffect(() => {
    const token = router.params?.token

    async function acceptInvitation() {
      // we shouldn't ever hit this, but belt and suspenders
      if (user.isAnonymous) return
      if (!token || typeof token !== 'string') {
        rootStore.navigateTo('home')
        return
      }

      if (user.isAdmin) {
        const isSectionInvitation = await cubit.isSectionInvitation(token)
        if (!isSectionInvitation) {
          toast.error(t('invitation.admin_denied'))
          return rootStore.navigateTo('home')
        }
      }

      const response = await user.consumeInvitation(token)

      switch (response.result) {
        case InvitationConsumeResult.success:
          toast.success(t('invitation.accepted'), {
            position: 'bottom-center',
          })
          break
        case InvitationConsumeResult.errorAlreadyConsumed:
          toast.error(t('invitation.already_accepted'))
          break
        case InvitationConsumeResult.errorCreatedByYou:
          toast.error(t('invitation.created_by_you'))
          break
        case InvitationConsumeResult.errorInvitationExpired:
          toast.error(t('invitation.expired'))
          break
        case InvitationConsumeResult.errorInvitationNotFound:
          toast.error(t('invitation.not_found'))
          break
        case InvitationConsumeResult.errorPromotionAlreadyConsumed:
          toast.error(t('invitation.already_accepted_promotion'))
          break
      }

      rootStore.navigateToWithHook('home', {
        hook: async () => {
          if (
            response.result !== InvitationConsumeResult.success ||
            !response.invitation?.sectionId
          ) {
            return
          }
          const cubit = new InstructorSectionCubit(
            repository,
            response.invitation.sectionId
          )
          const section = cubit.section

          // wait for the doc to prevent spinners
          await cubit.section.ready()

          if (section) {
            showDialog(() => <InvitationAcceptedDialog section={section} />)
          }
        },
      })
    }

    void acceptInvitation()
  }, [user, rootStore, router, showDialog, repository, t, cubit])

  return (
    <div className="relative box-border flex h-full w-full items-center justify-center overflow-auto rounded-3xl bg-core-tertiary">
      <Spinner />
    </div>
  )
}
