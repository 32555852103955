import classNames from 'classnames'
import { useCallback, useEffect, useRef, useState } from 'react'
import { DateTime } from 'luxon'
import { BreakoutButton } from './BreakoutButton'
import 'styles/datetime-picker.css'
import type { BreakoutDateTimeProps } from './BreakoutDateTimeInput'
import { useTranslation } from 'react-i18next'

type Props = Omit<BreakoutDateTimeProps, 'onChange'> & {
  // It's not possible to have a null here, so setting the right type def
  onChange: (value: DateTime) => void
}

export function BreakoutDateTimePicker({
  value,
  onChange,
  min,
  max,
  hideNowButton,
  hideTime,
}: Props) {
  const defaultTimeMode = DateTime.now()
    .toLocaleString(DateTime.TIME_SIMPLE)
    .toLowerCase()
    .match(/(am|pm)/)
    ? '12h'
    : '24h'

  const [timeMode, setTimeMode] = useState<'12h' | '24h'>(defaultTimeMode)
  const [view, setView] = useState<DateTime>(value ? value : DateTime.now())
  // Chrome's implementation ignores hours for min/max settings. It simplifies
  // a lot of things, so we're going to follow that logic.
  const minDate = min ? min.startOf('day') : undefined
  const maxDate = max ? max.endOf('day') : undefined
  const [selected, setSelected] = useState<DateTime>(view)

  const startWeek = view.startOf('month').startOf('week')
  const endWeek = view.endOf('month').endOf('week')

  const weeks = Array.from(
    { length: endWeek.diff(startWeek, 'weeks').weeks + 1 },
    (_, i) => startWeek.plus({ weeks: i })
  )

  const firstWeek = weeks[0]

  const { t } = useTranslation()

  const update = useCallback(
    (newValue: DateTime, { skipSelected }: { skipSelected?: boolean } = {}) => {
      if (minDate && newValue < minDate) return
      if (maxDate && newValue > maxDate) return
      if (!skipSelected) setSelected(newValue)
    },
    [setSelected, minDate, maxDate]
  )

  const handleSaveChange = (date: DateTime<boolean>) => {
    // We don't want to track milliseconds.
    const cleanDate = date.set({
      second: 0,
      millisecond: 0,
    })

    onChange?.(cleanDate)
  }

  const hoursRef = useRef<HTMLDivElement>(null)
  const minutesRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (hoursRef.current) {
      const div = hoursRef.current
      const selectedHour = div.querySelector('button[data-selected=true]')
      // protection against DOM implementation differences
      if (selectedHour && selectedHour.scrollIntoView) {
        selectedHour.scrollIntoView({
          block: 'center',
          inline: 'center',
        })
      }
    }
    if (minutesRef.current) {
      const div = minutesRef.current
      const selectedMinute = div.querySelector('button[data-selected=true]')
      // protection against DOM implementation differences
      if (selectedMinute && selectedMinute.scrollIntoView) {
        selectedMinute.scrollIntoView({
          block: 'center',
          inline: 'center',
        })
      }
    }
  }, [])

  return (
    <div className="w-full select-none bg-core-tertiary">
      <div className="flex h-[300px] flex-row justify-between gap-5 md:h-[250px]">
        <div className="calendar justify flex h-full w-full flex-1 flex-col">
          <div className="justify- flex w-full flex-row items-center justify-between">
            <div className="text-label-large px-2">
              {view.toFormat('LLL yyyy')}
            </div>
            <div>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  setView(view.minus({ months: 1 }))
                }}
                className="border border-core-tertiary px-2 py-1"
              >
                {'<'}
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  setView(view.plus({ months: 1 }))
                }}
                className="border border-core-tertiary px-2 py-1"
              >
                {'>'}
              </button>
            </div>
          </div>
          <div className="h-full w-full">
            <table className="text-body-small md:text-body-large h-full w-full">
              <thead>
                <tr>
                  {Array.from({ length: 7 }).map((_, i) => (
                    <th key={i}>
                      {firstWeek.plus({ days: i }).toFormat('ccc')[0]}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {weeks.map((week, weekIndex) => (
                  <tr key={weekIndex}>
                    {Array.from({ length: 7 }).map((_, i) => {
                      const day = week.plus({ days: i })
                      const current = selected.toISODate() === day.toISODate()
                      const key = day.toISODate()

                      if (minDate && day < minDate) {
                        return (
                          <td
                            data-testid={`datetime-${key}`}
                            key={key}
                            className="cursor-default p-2 py-1.5 text-center opacity-25"
                          >
                            {day.day}
                          </td>
                        )
                      }
                      if (maxDate && day >= maxDate) {
                        return (
                          <td
                            data-testid={`datetime-${key}`}
                            key={key}
                            className="cursor-default p-2 py-1.5 text-center opacity-25"
                          >
                            {day.day}
                          </td>
                        )
                      }

                      return (
                        <td
                          key={key}
                          className={classNames(
                            'cursor-pointer rounded-xl p-2 py-1.5 text-center',
                            day.month !== view.month && 'text-gray-500',
                            current && 'bg-core-primary text-core-on-primary'
                          )}
                        >
                          <button
                            id={`datetime-${key}`}
                            data-testid={`datetime-${key}`}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter' || e.key === ' ') {
                                e.preventDefault()
                                e.stopPropagation()
                                const newValue = view.set({
                                  year: day.year,
                                  month: day.month,
                                  day: day.day,
                                })
                                setView(newValue)
                                update(newValue)
                              }
                              let nextDay: DateTime | undefined
                              if (e.key === 'ArrowRight')
                                nextDay = day.plus({ days: 1 })
                              if (e.key === 'ArrowLeft')
                                nextDay = day.minus({ days: 1 })
                              if (e.key === 'ArrowDown')
                                nextDay = day.plus({ weeks: 1 })
                              if (e.key === 'ArrowUp')
                                nextDay = day.minus({ weeks: 1 })
                              if (nextDay) {
                                const nextDayKey = nextDay.toISODate()
                                const nextDayId = `datetime-${nextDayKey}`

                                const nextDayElement =
                                  document.getElementById(nextDayId)

                                if (nextDayElement) {
                                  nextDayElement.focus()
                                }
                              }
                            }}
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              const newValue = view.set({
                                year: day.year,
                                month: day.month,
                                day: day.day,
                              })
                              setView(newValue)
                              update(newValue)
                            }}
                          >
                            {day.day}
                          </button>
                        </td>
                      )
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex h-full flex-col">
          {!hideTime && (
            <div className="flex-0 text-body-medium md:text-body-large mb-2 flex justify-center gap-2">
              <button
                onClick={(e) => {
                  e.preventDefault()
                  setTimeMode('12h')
                }}
                className={classNames('p-1', {
                  'font-bold': timeMode === '12h',
                })}
              >
                {t('design_system.12h')}
              </button>{' '}
              <button
                onClick={(e) => {
                  e.preventDefault()
                  setTimeMode('24h')
                }}
                className={classNames('p-1', {
                  'font-bold': timeMode === '24h',
                })}
              >
                {t('design_system.24h')}
              </button>
            </div>
          )}

          {!hideTime && (
            <div className="times text-body-medium md:text-body-large flex flex-1 flex-row gap-2 overflow-hidden">
              <div
                className="hours overflow-y-auto bg-core-secondary"
                ref={hoursRef}
              >
                {timeMode === '24h' &&
                  Array.from({ length: 24 }).map((_, i) => (
                    <button
                      key={i}
                      data-testid={`time-${i}`}
                      data-selected={i === selected.hour}
                      className={classNames(
                        'block cursor-pointer p-2 py-1.5 text-center',
                        {
                          'bg-core-primary text-core-on-primary':
                            i === selected.hour,
                        }
                      )}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          e.preventDefault()
                          e.stopPropagation()
                          setView(view.set({ hour: i }))
                          update(selected.set({ hour: i }))
                        }
                      }}
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setView(view.set({ hour: i }))
                        update(selected.set({ hour: i }))
                      }}
                    >
                      {i < 10 ? '0' + i : i}
                    </button>
                  ))}
                {timeMode === '12h' &&
                  Array.from({ length: 12 }).map((_, i) => {
                    const hour = i === 0 ? 12 : i
                    const am = view.hour < 12
                    let currentValueIn12 = am ? view.hour : view.hour - 12
                    if (currentValueIn12 === 0) currentValueIn12 = 12
                    return (
                      <button
                        key={i}
                        data-testid={`time-${i}`}
                        data-selected={i === selected.hour}
                        className={classNames(
                          'block cursor-pointer p-2 py-1.5 text-center',
                          {
                            'bg-core-primary text-core-on-primary':
                              hour === currentValueIn12,
                          }
                        )}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            e.preventDefault()
                            e.stopPropagation()
                            setView(view.set({ hour: i }))
                            update(selected.set({ hour: i }))
                          }
                        }}
                        onClick={(e) => {
                          e.stopPropagation()
                          e.preventDefault()
                          const hourClicked = hour === 12 ? 0 : hour
                          const newHour = am ? hourClicked : hourClicked + 12
                          setView(view.set({ hour: newHour }))
                          update(selected.set({ hour: newHour }))
                        }}
                      >
                        {hour < 10 ? '0' + hour : hour}
                      </button>
                    )
                  })}
              </div>
              <div
                className="minutes overflow-auto bg-core-secondary"
                ref={minutesRef}
              >
                {Array.from({ length: 60 }).map((_, i) => (
                  <button
                    key={i}
                    data-testid={`minute-${i}`}
                    data-selected={i === selected.minute}
                    className={classNames(
                      'block cursor-pointer p-2 py-1.5 text-center',
                      {
                        'bg-core-primary text-core-on-primary':
                          i === selected.minute,
                      }
                    )}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        e.preventDefault()
                        e.stopPropagation()
                        setView(view.set({ minute: i }))
                        update(selected.set({ minute: i }))
                      }
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      setView(view.set({ minute: i }))
                      update(selected.set({ minute: i }))
                    }}
                  >
                    {i < 10 ? '0' + i : i}
                  </button>
                ))}
              </div>
            </div>
          )}
          {timeMode === '12h' && !hideTime && (
            <div className="flex-0 text-body-medium md:text-body-large mt-2 flex justify-center gap-2">
              <button
                onClick={(e) => {
                  e.preventDefault()
                  const am = view.hour < 12
                  const newHour = am ? view.hour : view.hour - 12
                  setView(view.set({ hour: newHour }))
                  update(selected.set({ hour: newHour }))
                }}
                className={classNames('p-1', {
                  'font-bold': view.hour < 12,
                })}
                data-testid="datetime-picker-am"
              >
                {t('design_system.am')}
              </button>{' '}
              <button
                onClick={(e) => {
                  e.preventDefault()
                  const am = view.hour < 12
                  const newHour = am ? view.hour + 12 : view.hour
                  setView(view.set({ hour: newHour }))
                  update(selected.set({ hour: newHour }))
                }}
                className={classNames('p-1', {
                  'font-bold': view.hour >= 12,
                })}
                data-testid="datetime-picker-pm"
              >
                {t('design_system.pm')}
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="mt-3 flex items-center justify-between">
        {hideNowButton && <div />}
        {!hideNowButton && (
          <BreakoutButton
            data-testid="datetime-picker-now"
            size="small"
            kind="secondary"
            onClick={() => {
              update(DateTime.now())
              setView(DateTime.now())
            }}
          >
            {t('design_system.now')}
          </BreakoutButton>
        )}
        <div className="text-body-small md:text-body-medium px-1">
          {getFormattedTime(selected, timeMode, hideTime)}
        </div>
        <BreakoutButton
          data-testid="datetime-picker-ok"
          size="small"
          onClick={() => handleSaveChange(selected)}
        >
          {t('design_system.ok')}
        </BreakoutButton>
      </div>
    </div>
  )
}

function getFormattedTime(
  time: DateTime,
  timeMode: '12h' | '24h',
  hideTime?: boolean
) {
  if (hideTime) return time.toFormat('LLL d, yyyy')
  return timeMode === '12h'
    ? time.toFormat('LLL d, yyyy h:mm a ZZZZ')
    : time.toFormat('LLL d, yyyy HH:mm ZZZZ')
}
