import { InfoIcon } from 'components/icons/Info'
import { Spinner } from 'components/Spinner'
import { useMediaPermissions } from 'hooks/av-test'
import { PermissionsErrorBanner } from 'pages/av-test/PermissionErrorBanner'
import { lazy, useState } from 'react'
import { useTranslation } from 'react-i18next'

const NewAvTest = lazy(() => import('pages/av-test/NewAvTest'))

export const ProfileAvTest = () => {
  const { t } = useTranslation()
  const [showPermissionsBanner, setShowPermissionsBanner] = useState(false)

  const [permissionState] = useMediaPermissions({
    onDenied: () => setShowPermissionsBanner(true),
  })

  if (permissionState === 'waiting') {
    return <Spinner size={3} />
  }

  return (
    <div className="flex h-full min-h-0 flex-col pb-6">
      {showPermissionsBanner && (
        <PermissionsErrorBanner
          onDismiss={() => setShowPermissionsBanner(false)}
        />
      )}
      <h1 className="text-headline-large">{t('profile.av')}</h1>
      <div className="text-body-large mb-3 text-core-on-tertiary">
        {t('profile.av_description')}
      </div>
      {permissionState !== 'granted' && (
        <div className="mt-6 flex flex-1 flex-col items-center justify-center rounded-3xl bg-surface px-6">
          <InfoIcon className="fill-fixed-accent-color" size={32} />
          <div className="text-headline-small mt-1 text-core-on-tertiary">
            {t('profile.denied_permissions')}
          </div>
          <div className="text-body-large mt-2 text-core-on-tertiary">
            {t('profile.denied_permissions_description')}
          </div>
        </div>
      )}
      {permissionState === 'granted' && (
        <div className="mx-auto mt-10 min-h-0 flex-1">
          <NewAvTest permissionState={permissionState} />
        </div>
      )}
    </div>
  )
}
